import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {HtmlToText, getParam} from '../utils/utils';
import {formatText, truncate} from '../utils/utils';

import Buffering from "../components/Buffering"; 

import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewEntity from "../components/ViewEntity";
import ViewLogicalModel from "../components/ViewLogicalModel";
import ViewMetadata from "../components/ViewMetadata";

import FormAttribute from "../components/FormAttribute";
import FormConcept from "../components/FormConcept";


import Revision from "../components/Revision";
import ListRevisions from "../components/ListRevisions";


import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

class Entity extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            renderState: null
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "dot";


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVizClick = this.handleVizClick.bind(this);

        this.addAttribute = this.addAttribute.bind(this);
        this.removeAttribute = this.removeAttribute.bind(this);
        this.updateAttribute = this.updateAttribute.bind(this);
        this.moveAttributeUp = this.moveAttributeUp.bind(this);
        this.moveAttributeDown = this.moveAttributeDown.bind(this);


        this.refRevision = React.createRef();
        this.loadedRevisions = this.loadedRevisions.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/`;

    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){


            if (this.state.vizNodeId){
                let partsVizNodeId = this.state.vizNodeId.split('_');
                switch(partsVizNodeId[0]){
                    case 'concept':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/concept/${partsVizNodeId[1]}`);
                        }
                        break;
                    case 'entity':
                        if (partsVizNodeId.length > 3){
                            switch(partsVizNodeId[2]){
                                case 'structure':
                                    this.props.navigation(`/structure/${partsVizNodeId[3]}`);
                                    break;
                            }
                        }else{
                            if (partsVizNodeId.length > 1){
                                this.props.navigation(`/entity/${partsVizNodeId[1]}`);
                            }
                        }
                        break;
                    case 'structure':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/structure/${partsVizNodeId[1]}`);
                        }
                        break;
        
                }

            }


            if (this.state.loadLogicalModel){
                this.loadLogicalModel();
            }

            if (this.state.loadEntity){
                this.loadEntity();
            }

            if (this.state.renderState == "loading"){
                if (this.state.isFailed){
                    StateChange.renderState = "failed";
                }
                else{
                    switch (this.state.mode){
                        case "new":
                            if (this.state.isLoadedLogicalModel){
                                StateChange.renderState = "loaded";
                            }
                        default:
                            if (this.state.isLoadedEntity){
                                StateChange.LogicalModel = this.state.Entity.LogicalModel;
                                StateChange.renderState = "loaded";
                            }
                            break;                        
                    }
                }
            }

            if (this.state.renderState == "updating"){
                if (this.state.isError){
                    StateChange.renderState = "error";
                    StateChange.isError = false;
                }
                else{
                    if (this.state.isUpdated){
                        StateChange.isUpdated = false;
                        StateChange.renderState = "updated";
                    }
                }
            }

            if (this.state.renderState == "updated"){
                if (this.context.hasLayout){
                    if (this.state.LogicalModel){
                        this.context.Layout.setLeftAsLogicalModel(this.state.LogicalModel._id);
                    }
                }
                this.props.navigation(`/entity/${this.state.idEntity}`);
            }

            if (this.state.renderState == "cancelled"){
                switch (this.state.mode){
                    case "edit":
                        this.props.navigation(`/entity/${this.state.idEntity}`);
                        break;
                    case "new":
                        this.props.navigation(this.FallBackNavigation);
                        break;
                }
            }

            if (this.state.renderState == "loaded"){

                StateChange.OfferEdit = false;
                if (this.state.LogicalModel){
                    if (this.dsd.loggedon){
                        if (this.dsd.user.id == this.state.LogicalModel.idUser){
                            StateChange.OfferEdit = true;
                        }
                    }
                }

                if (!StateChange.OfferEdit){
                    switch (this.state.mode){
                        case "new":
                        case "edit":
                        case "remove":
                            if (!this.state.OfferEdit){
                                StateChange.renderState = "denied"
                                StateChange.errorMessage = "You cannot add or edit Entities to this Logical Model"
                            }
                            break;
                    }
                }

            }
            this.setState(StateChange);

        }


    }
    
    loadLogicalModel(){

        let StateChange = {};
        StateChange.loadLogicalModel = false;
        StateChange.isLoadedLogicalModel = false;
        StateChange.LogicalModel = null;

        if (this.state.idLogicalModel){
            StateChange.renderState = "loading";
            this.dsd.getLogicalModel(this.state.idLogicalModel,"LogicalModel","isLoadedLogicalModel");
        }

        this.setState(StateChange);

        return;
        
    };

    loadEntity(){

        let StateChange = {};
        StateChange.loadEntity = false;
        StateChange.isLoadedEntity = false;
        StateChange.Entity = null;

        StateChange.isLoadedEntityLinks = false;
        StateChange.EntityLinks = null;


        if (this.state.idEntity){
            StateChange.renderState = "loading";
            this.dsd.getEntity(this.state.idEntity,"Entity","isLoadedEntity");
            this.dsd.listEntityLinks({idEntity:this.state.idEntity},"EntityLinks","isLoadedEntityLinks");
        }

        this.setState(StateChange);

        return;
        
    };



    setup(){

        let StateChange = {

            mode: "view",

            idEntity: null,
            idLogicalModel: null,

            LogicalModel: null,
            loadLogicalModel: false,
            isLoadedLogicalModel: false,

            Entity: null,
            loadEntity: false,
            isLoadedEntity: false,

            isUpdated: false,

            isFailed: false,
            isError: false,
            errorMessage: null,

            renderState: null,

            FormData: {
                status:'in use'
            },

            OfferEdit:false,

            Controls: {
                vizFullSize :true
            },

            countRevisions: 0,

            vizNodeId: null

        }

        if (getParam('mode', this.props)){
            StateChange.mode = getParam('mode', this.props);
        }
        StateChange.idEntity = getParam('id', this.props);
        StateChange.idLogicalModel = getParam('idLogicalModel', this.props);

        switch (StateChange.mode){
            case 'new':
                if (!StateChange.idLogicalModel){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Logical Model not specified when adding a Entity"
                }
                break;
            default:
                if (StateChange.idLogicalModel){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Logical Model should not be specified for an existing Entity"
                }
                if (!StateChange.idEntity){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Entity not be specified for an existing Entity"
                }
                break;
        }

        if (StateChange.mode == "new"){
            if (StateChange.idLogicalModel){
                this.FallBackNavigation = `/logicalmodel/${StateChange.idLogicalModel}`;
            }        
        }



        if (StateChange.idLogicalModel){
            StateChange.loadLogicalModel = true;
        }

        if (StateChange.idEntity){
            StateChange.loadEntity = true;
        }


        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        switch (FormFieldName){
            case 'vizFullSize':
                let Controls = this.state.Controls;
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        
                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    
    handleSubmit(event) {

        switch (event.target.name){
            
            case 'btnCancel':

                this.setState({
                    renderState: "cancelled"
                })

                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false,
                    renderState: "updating"
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addEntityToModel(this.state.idLogicalModel,this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editEntity(this.state.idEntity,this.state.FormData);
                        this.refRevision.current.update();
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };
    
        };

        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
        }

        this.setState(StateChange);

        event.preventDefault();
    };


    addConcept(){
        let FormData = this.state.FormData;

        if (!('Concepts' in FormData)){
            FormData['Concepts'] = [];
        }
        if (!FormData.Concepts){
            FormData['Concepts'] = [];
        }

        FormData.Concepts.push({});
        this.setState({"FormData": FormData});
    }

    removeConcept(seq){

        let FormData = this.state.FormData;

        if (!('Concepts' in FormData)){
            FormData['Concepts'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Concepts.length;pos++){
            let item = FormData.Concepts[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Concepts = arrNew;
        this.setState({"FormData": FormData});

    }


    updateConcept(seq, Concept){

        let FormData = this.state.FormData;

        if (!('Concepts' in FormData)){
            FormData['Concepts'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Concepts.length;pos++){
            let item = FormData.Concepts[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Concept;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Concept);
        }

        FormData.Concepts = arrNew;
        this.setState({"FormData": FormData});

    }

    addAttribute(){
        let FormData = this.state.FormData;

        if (!('Attributes' in FormData)){
            FormData['Attributes'] = [];
        }
        if (!FormData.Attributes){
            FormData['Attributes'] = [];
        }

        FormData.Attributes.push({});
        this.setState({"FormData": FormData});
    }

    removeAttribute(seq){

        let FormData = this.state.FormData;

        if (!('Attributes' in FormData)){
            FormData['Attributes'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Attributes.length;pos++){
            let item = FormData.Attributes[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Attributes = arrNew;
        this.setState({"FormData": FormData});

    }

    moveAttributeUp(seq){

        let FormData = this.state.FormData;

        if (!('Attributes' in FormData)){
            FormData['Attributes'] = [];
        }
        let arrNew = []
        for (let pos=0;pos<FormData.Attributes.length;pos++){
            let item = FormData.Attributes[pos];
            if (pos == (seq-2)){
                item = FormData.Attributes[pos+1];
            }
            if (pos == (seq-1)){
                item = FormData.Attributes[pos-1];
            }
            arrNew.push(item); 
        }

        FormData.Attributes = arrNew;
        this.setState({"FormData": FormData});

    }

    moveAttributeDown(seq){

        let FormData = this.state.FormData;

        if (!('Attributes' in FormData)){
            FormData['Attributes'] = [];
        }
        let arrNew = []
        for (let pos=0;pos<FormData.Attributes.length;pos++){
            let item = FormData.Attributes[pos];
            if (pos == (seq-1)){
                item = FormData.Attributes[pos+1];
            }
            if (pos == (seq)){
                item = FormData.Attributes[pos-1];
            }
            arrNew.push(item); 
        }

        FormData.Attributes = arrNew;
        this.setState({"FormData": FormData});

    }


    updateAttribute(seq, Attribute){


        if ('description' in Attribute){
            Attribute.description = sanitizeHtml(Attribute.description);
        }


        let FormData = this.state.FormData;

        if (!('Attributes' in FormData)){
            FormData['Attributes'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Attributes.length;pos++){
            let item = FormData.Attributes[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Attribute;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Attribute);
        }

        FormData.Attributes = arrNew;
        this.setState({"FormData": FormData});

    }


    handleVizClick(event) {

        let StateChange = {};

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }
        

        StateChange.vizNodeId = idNode;

        this.setState(StateChange);

        event.preventDefault();

    };


    loadedRevisions(count) {

        let StateChange = {};

        StateChange.countRevisions = 0;
        if (count){
            StateChange.countRevisions = count;
        }

        this.setState(StateChange);


    };


    visualise(){
    
        if (!this.state.Entity){
            return null;
        }


        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };


        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.fit = false;
        vizoptions.width = null;
        vizoptions.height = null;
        vizoptions.zoom = false;
        vizoptions.scale = 1;

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            return(
                <div>
                    <table>
                        <tr>
                            <td>
                            <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                            </td>
                        </tr>
                    </table>

                    <div name="divViz" style={styleDiv} onClick={this.handleVizClick}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };


    render() {

        switch (this.state.renderState){
            case "invalid":
            case "failed":
            case "denied":
                let Message = "An error has occured";
                if (this.state.errorMessage){
                    Message = this.state.errorMessage;
                }
                return <PD.ErrorBox>{Message}</PD.ErrorBox>
                break;
            case "loading":
                return <Buffering/>
                break;
            case "cancelled":
                return <p>Cancelled</p>
                break;
    
        }


        switch (this.state.mode){
            case 'new':
            case 'edit':

                if (this.state.Entity){
                    this.state.FormData = this.state.Entity;
                }

                switch (this.state.renderState){
                    case "loaded":
                    case "updating":
                    case "error":
                        return (
                            <div>

                                <PD.Heading>{this.state.mode} Entity</PD.Heading>

                                {this.state.renderState == "error" &&
                                    <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                                }

                                <form> 
                                    <PD.Input label="Name" width="60" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                                    <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>
                                    <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                                    <PD.Select label="Status" name='status'  onChange={this.handleChange} value={this.state.FormData.status}>
                                        {
                                            this.dsd.config.EntityStatuses.map(
                                                (status, posStatus) => 
                                                    {
                                                        return(
                                                            <option key={posStatus} value={status}>{status}</option>
                                                        )
                                                    }
                                            )
                                        }
                                    </PD.Select>

                                    {this.FormConcepts()}
                                    {this.FormAttributes()}

                                    <Revision ref={this.refRevision}  idAbout={this.state.idEntity}/>

                                    
                                    {(() => {
                                        switch (this.state.renderState) {
                                            case "updating":
                                                return (
                                                    <div>
                                                        <PD.Button disabled={true} type="submit" name="btnSave">
                                                            <Buffering/>
                                                        </PD.Button>
                                                    </div>
                                                )
                                            default:
                                                return (
                                                    <div>
                                                        <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave" value="Save" />    
                                                        <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" /> 
                                                    </div>
                                                )
                                        }
                                    })()}

                                </form>

                            </div>    
                        );
                }
                break;
            case 'remove':
                return (
                    <div>
                        <PD.Heading>{this.state.mode} Entity</PD.Heading>

                        {this.state.isFailed &&
                            <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                        }

                        <PD.Button onClick={this.handleSubmit} type="warning" value="confirm remove?" />
                        <ViewEntity Entity={this.state.Entity}/>
                    </div>
                )
            case 'view':
            default:
                
                switch (this.state.renderState){
                    case "loaded":

                        this.viz = this.dsd.vizEntity({Entity:this.state.Entity, EntityLinks:this.state.EntityLinks}, this.state.Controls, this.vizEngine );

                        let countConcepts = 0;
                        if (this.state.Entity.Concepts){
                            countConcepts = this.state.Entity.Concepts.length;
                        }

                        let countAttributes = 0;
                        if (this.state.Entity.Attributes){
                            countAttributes = this.state.Entity.Attributes.length;
                        }


                        return (
                            <div>
                                <div>
                                    <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                    <PD.Heading>Entity:{this.state.Entity.name}</PD.Heading>
                                </div>

                                {this.state.OfferEdit &&
                                    <span style={{paddingRight:10}}>
                                            <PD.Button onClick={this.handleMode} type="submit" value="edit" />                        
                                    </span>
                                }

                                <ViewEntity Entity={this.state.Entity}/>

                                <br/>

                                <PD.Tabs>

                                <div label='Visualise'>
                                    <table>
                                        <tr>
                                            <td style={{verticalAlign: 'top', width: 'auto'}}>
                                                {this.visualise()}
                                            </td>
                                        </tr>
                                    </table>
                                </div>



                                <div label={`Concepts(${countConcepts})`}>

                                    {isArray(this.state.Entity.Concepts) &&
                                        <PD.Table className="list">
                                            <PD.Thead>
                                                <PD.Tr>
                                                    <PD.Th>Name</PD.Th>
                                                    <PD.Th>Concept Model</PD.Th>
                                                    <PD.Th>Description</PD.Th>
                                                </PD.Tr>
                                            </PD.Thead>

                                            <PD.Tbody>
                                                {
                                                    this.state.Entity.Concepts.map(
                                                        (EntityConcept, pos) => 
                                                        {

                                                            if (EntityConcept.Concept){
                                                                return (
                                                                    <PD.Tr key={pos}>
                                                                        <PD.Td>
                                                                            <PD.PdNavLink to={"/concept/" + EntityConcept.Concept._id} > {EntityConcept.Concept.name}</PD.PdNavLink>
                                                                        </PD.Td>

                                                                        <PD.Td>
                                                                            {(() => {
                                                                                if (EntityConcept.Concept.ConceptModel){
                                                                                    return EntityConcept.Concept.ConceptModel.name;
                                                                                }
                                                                                return null;
                                                                            })()}
                                                                    </PD.Td>
                                                                        
                                                                        <PD.Td>
                                                                            {formatText(truncate(HtmlToText(EntityConcept.Concept.description), 400))}
                                                                        </PD.Td>

                                                                    </PD.Tr>

                                                                )
                                                            }else{
                                                                return(
                                                                    <PD.Tr key={pos}>
                                                                        <PD.Td>not permitted"</PD.Td>
                                                                    </PD.Tr>
                                                                )
                                                            }
                                                        }
                                                    )
                                                }
                                            </PD.Tbody>
                                        </PD.Table>
                                    }

                                </div>


                                <div label={`Attributes(${countAttributes})`}>

                                    {isArray(this.state.Entity.Attributes) &&
                                        <PD.Table className="list">
                                            <PD.Thead>
                                                <PD.Tr>
                                                    <PD.Th>Name</PD.Th>
                                                    <PD.Th>Description</PD.Th>
                                                    <PD.Th>Data Type</PD.Th>
                                                    <PD.Th>Structure</PD.Th>
                                                    <PD.Th>Occurs</PD.Th>
                                                    <PD.Th>Required</PD.Th>
                                                </PD.Tr>
                                            </PD.Thead>

                                            <PD.Tbody>
                                                {
                                                    this.state.Entity.Attributes.map(
                                                        (Attribute, pos) => 
                                                        {
                                                            return (
                                                                <PD.Tr key={pos}>
                                                                    <PD.Td>
                                                                        {Attribute.name}
                                                                    </PD.Td>
                                                                   
                                                                    <PD.Td>
                                                                        {formatText(truncate(HtmlToText(Attribute.description), 4000))}
                                                                    </PD.Td>


                                                                    {(() => {
                                                                        switch (Attribute.type) {
                                                                            case "structure":
                                                                                if (Attribute.Structure){
                                                                                    return (
                                                                                        <>
                                                                                            <PD.Td/>
                                                                                            <PD.Td>
                                                                                                <PD.PdNavLink to={"/structure/" + Attribute.Structure._id} > {Attribute.Structure.name}</PD.PdNavLink>
                                                                                            </PD.Td>
                                                                                        </>
                                                                                    );
                                                                                }                                                                                
                                                                                return (
                                                                                    <>
                                                                                        <PD.Td/>
                                                                                        <PD.Td/>
                                                                                    </>    
                                                                                )
                                                                                break;
                                                                            default:
                                                                                return(
                                                                                    <>
                                                                                        <PD.Td>
                                                                                            {Attribute.dataType}
                                                                                        </PD.Td>
                                                                                        <PD.Td/>
                                                                                    </>
                                                                                )
                                                                        }
                                                                    })()}


                                                                    <PD.Td>
                                                                        {Attribute.occurs}
                                                                    </PD.Td>

                                                                    <PD.Td>
                                                                        {Attribute.required}
                                                                    </PD.Td>


                                                                </PD.Tr>

                                                            )
                                                        }
                                                    )
                                                }
                                            </PD.Tbody>
                                        </PD.Table>
                                    }

                                </div>

                                    <div label='Logical Model'>
                                        <ViewLogicalModel LogicalModel={this.state.LogicalModel} />
                                    </div>

                                    <div label='Metadata'>
                                        <ViewMetadata Data={this.state.Entity} />
                                    </div>

                                    <div label={`Revisions(${this.state.countRevisions})`} forceRender={true}>
                                        <ListRevisions OfferEdit={this.state.OfferEdit} filters={{idAbout:this.state.idEntity}} onLoad={this.loadedRevisions}/>
                                    </div>



                                    <div label="used in ...">
                                    </div>

                                    <div label='Developer'>
                                        <table>
                                            {this.viz &&
                                                <tr>
                                                    <th style={{verticalAlign:"top"}}>dot</th>
                                                    <td><pre>{this.viz.dot}</pre></td>
                                                </tr>
                                            }
                                            {this.state.csv &&
                                                <tr>
                                                        <th style={{verticalAlign:"top"}}>csv</th>
                                                        <td><pre>{this.state.csv}</pre></td>
                                                </tr>
                                            }
                                        </table>
                                    </div>


                                </PD.Tabs>


                            </div>
                        )
                }
        };

        return <div />;
    };


    FormConcepts(){

        let seqNext = 1;
        if (this.state.FormData.Concepts){
            seqNext = this.state.FormData.Concepts.length + 1;
        }
        return (
            <fieldset>
                <legend>Concepts</legend>
                {this.state.FormData.Concepts &&
                    this.state.FormData.Concepts.map(
                        (Concept, posConcept) => 
                        {
                            let seq = posConcept+1;
                            return(
                                this.FormConcept('edit', seq, Concept)
                            )
                        }
                    )
                }
                {this.FormConcept('new', seqNext)}
            </fieldset>
        );

    }

    FormConcept(mode, seq, Concept = {}){
        return(
            <div>
                <FormConcept 
                    key={seq.toString()} 
                    mode={mode} 
                    parent={this} 
                    Concept={Concept} 
                    seq={seq}/>
            </div>
        )
    }

    FormAttributes(){

        let seqNext = 1;
        if (this.state.FormData.Attributes){
            seqNext = this.state.FormData.Attributes.length + 1;
        }
        return (
            <fieldset>
                <legend>Attributes</legend>
                {this.state.FormData.Attributes &&
                    this.state.FormData.Attributes.map(
                        (Attribute, posAttribute) => 
                        {
                            let seq = posAttribute+1;
                            return(
                                this.FormAttribute('edit', seq, Attribute)
                            )
                        }
                    )
                }
                {this.FormAttribute('new', seqNext)}
            </fieldset>
        );

    }

    FormAttribute(mode, seq, Attribute = {}){

        let onMoveUp = null;
        if (seq>1) {
            onMoveUp = this.moveAttributeUp;
        }

        let onMoveDown = null;
        if (this.state.FormData.Attributes){
            if (seq<this.state.FormData.Attributes.length) {
                onMoveDown = this.moveAttributeDown;
            }
        }


        return(
            <div>
                <FormAttribute 
                    key={seq.toString()} 
                    mode={mode} parent={this} 
                    onAdd={this.addAttribute}
                    onRemove={this.removeAttribute}
                    onUpdate={this.updateAttribute}
                    {...(onMoveUp && { onMoveUp:onMoveUp})}
                    {...(onMoveDown && { onMoveDown:onMoveDown})}
                    Attribute={Attribute} 
                    seq={seq}
                />
            </div>
        )
    }


};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Entity {...props} navigation={navigation} location={location} params={params} />;
  }
