import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';


import * as PD from '../classes/pdStyle';
import {HtmlToText, cloneObject, getParam} from '../utils/utils';
import {formatDate, formatText, truncate} from '../utils/utils';



import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewConceptModel from "../components/ViewConceptModel";
import ViewMetadata from "../components/ViewMetadata";
import ListConcepts from "../components/ListConcepts";
import ListLinks from "../components/ListLinks";

import Version from "../components/Version";
import ListVersions from "../components/ListVersions";
import ViewVersion from "../components/ViewVersion";


import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

import Buffering from "../components/Buffering";

import { json2csv } from 'json-2-csv';

import JSZip from "jszip";

class ConceptModel extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            loadData:false
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "dot";

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVizClick = this.handleVizClick.bind(this);

        this.handleAddVersion = this.handleAddVersion.bind(this);
        this.VersionOnActioned = this.VersionOnActioned.bind(this);
        this.loadedVersions = this.loadedVersions.bind(this);
        this.loadedConcepts = this.loadedConcepts.bind(this);
        this.loadedLinks = this.loadedLinks.bind(this);



        this.handleConceptFilters = this.handleConceptFilters.bind(this);
        this.handleLinkFilters = this.handleLinkFilters.bind(this);


        this.handleExport = this.handleExport.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/conceptmodels`;


    }

    componentDidMount(){

       this.setup();

    }


    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.vizNodeId){
                let partsVizNodeId = this.state.vizNodeId.split('_');
                switch(partsVizNodeId[0]){
                    case 'concept':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/concept/${partsVizNodeId[1]}`);
                        }
                        break;
                }
            }
            
            this.makeCsv();
            
            if (this.state.loadData){
                this.loadData(this.state.idConceptModel);
                StateChange.updateToggle = Number(!this.state.updateToggle)
                StateChange.loadData = false;
            }

            if (this.state.mode === 'new'){
                StateChange.isLoaded = true;
            }


            if (this.state.isUpdated){
                StateChange.isUpdated = false;
                StateChange.mode = 'view';
                StateChange.loadData = true;
            }
            else{
                if (this.state.idConceptModel != prevState.idConceptModel){
                    if (this.state.idConceptModel){
                        this.onUpdateNavigation = `/conceptmodel/${this.state.idConceptModel}`;
                        StateChange.loadData = true;
                    }
                }
            }


            if (this.state.isLoaded) {
    
                StateChange.OfferEdit = false;
    
                if (this.state.ConceptModel){
                    StateChange.FormData = this.state.ConceptModel;
                    if (this.dsd.loggedon){
                        if (this.dsd.user.id == this.state.ConceptModel.idUser){
                            StateChange.OfferEdit = true;
                        }
                    }
                    
                    if (this.state.ConceptModel.uri){
                        this.dsd.uriDereference(this.state.ConceptModel.uri);
                    }

                    this.setLayoutContent();

                }

                if (this.state.uriDereference){
                    StateChange.rdfs = this.state.uriDereference;
                }
            }
    
            if (this.state.idConceptModel){
                for (var i in window.Breadcrumb){
                    if (window.Breadcrumb[i][0] === 'conceptmodel'){
                        window.Breadcrumb = window.Breadcrumb.slice(0,i);
                        break;
                    }
                }
                this.Breadcrumb = JSON.parse(JSON.stringify(window.Breadcrumb));
    
                window.Breadcrumb.push(['concept model',`/conceptmodel/${this.state.idConceptModel}`]);

                this.FallBackNavigation = `../conceptmodels`;
                this.onUpdateNavigation = this.FallBackNavigation;
    
            };

            this.setState(StateChange);

        }

    }
    
    loadData(id){

        if (id !== undefined){
            this.dsd.getConceptModel(id);
        }
        
        return;
        
    };

    setup(){


        let StateChange = {
            id: null,
            isLoaded: false,
            isUpdated: false,
            isFailed: false,
            loadData: false,

            OfferEdit: false,

            FormData: {},
            Controls: {
                vizFullSize :true,
                vizSuperConcepts: false
            },

            ConceptFilters: {
                status:'in use'
            },
            LinkFilters: {
                status:'in use'
            },

            idConceptModel: null,

            ConceptModel: null,
            Concepts: null,
            Links: null,

            idVersion: null,

            action: null,

            updateToggle: 0,

            countVersions: 0,
            countConcepts: 0,
            countLinks: 0,

            vizNodeId: null,

            csv: null,
            rdfs: null

        }

        StateChange.mode = getParam('mode', this.props);

        StateChange.idConceptModel = getParam('id', this.props);
        if (StateChange.idConceptModel){
            StateChange.loadData = true;
        }

        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        StateChange.idVersion = getParam('idVersion', this.props);


        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let Controls = this.state.Controls;
        switch (FormFieldName){
            case 'vizFullSize':
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            case 'vizSuperConcepts':
                Controls[FormFieldName] = FormFieldValue;
                this.setState({"Controls": Controls});
                break;    
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        
                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    

    handleConceptFilters(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let ConceptFilters = this.state.ConceptFilters;
        ConceptFilters[FormFieldName] = FormFieldValue;
        this.setState({"ConceptFilters": ConceptFilters});

        return;

    };


    handleLinkFilters(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let LinkFilters = this.state.LinkFilters;
        LinkFilters[FormFieldName] = FormFieldValue;
        this.setState({"LinkFilters": LinkFilters});

        return;

    };



    handleSubmit(event) {

        switch (event.target.name){
            case 'btnCancel':
                switch (this.state.mode){
                    case 'new':
                        this.props.navigation(this.FallBackNavigation);
                        break;
                    case 'edit':
                        this.loadData(this.state.idConceptModel);
                        this.setState({mode: 'view'});
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };

                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false,
                    invalidFields: false
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addConceptModel(this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editConceptModel(this.state.idConceptModel,this.state.FormData);
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };
    
        };

        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
            case 'btnAddConcept':
                this.props.navigation(`/conceptmodel/${this.state.idConceptModel}/concept?mode=new`);
                break;
            case 'btnAddLink':
                this.props.navigation(`/conceptmodel/${this.state.idConceptModel}/link?mode=new`);

                break;
        }

        this.setState(StateChange);

        event.preventDefault();
    };


    handleAddVersion(event) {

        this.setState({newVersion:true});

    }


    handleVizClick(event) {

        let StateChange = {};

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }
        

        StateChange.vizNodeId = idNode;

        this.setState(StateChange);

        event.preventDefault();

    };


    async handleExport(event){

        const zip = new JSZip();
        
        let ConceptModel = cloneObject(this.state.ConceptModel);

// remove deprecated items
        let Concepts = [];
        if (ConceptModel.Concepts){
            for (let pos=0; pos<ConceptModel.Concepts.length; pos++){
                let Concept = ConceptModel.Concepts[pos];
                switch (Concept.status){
                    case 'deprecated':
                        break;
                    default:
                        Concepts.push(Concept);
                        break;
                }
                
            }
        }
        ConceptModel.Concepts = Concepts;

        let Links = [];
        if (ConceptModel.Links){
            for (let pos=0; pos<ConceptModel.Links.length; pos++){
                let Link = ConceptModel.Links[pos];
                switch (Link.status){
                    case 'deprecated':
                        break;
                    default:
                        Links.push(Link);
                        break;
                }
                
            }
        }
        ConceptModel.Links = Links;

        let strConceptModel = JSON.stringify(ConceptModel, null, 4);

        zip.file(`conceptmodel_${this.state.idConceptModel}.json`, strConceptModel);

        zip.file(`conceptmodel_${this.state.idConceptModel}.dot`,this.viz.dot);

        let listConcepts = {};

        for (let posC=0; posC<ConceptModel.Concepts.length; posC++){
            let Concept = cloneObject(ConceptModel.Concepts[posC]);
            Concept.ConceptModel = ConceptModel;
            listConcepts[Concept._id] = Concept;

            let ConceptLinks = [];
            for (let posL=0; posL < ConceptModel.Links.length; posL++){
                let Link = cloneObject(ConceptModel.Links[posL]);
                if (Link.idConceptFrom == Concept._id || Link.idConceptTo == Concept._id){
                    ConceptLinks.push({Concept:Concept, Link:Link});
                }
            }

            let dotConcept = this.dsd.vizConcept({Concept:Concept, ConceptModel: this.state.ConceptModel, ConceptLinks: ConceptLinks}).dot;
            zip.file(`concept_${Concept._id}.dot`,dotConcept);
        }

        // concepts that are not in this conceptmodel

        for (let posL=0; posL < ConceptModel.Links.length; posL++){
            let Link = cloneObject(ConceptModel.Links[posL]);
            if (Link.ConceptFrom){
                if (Link.ConceptFrom.ConceptModel){
                    if (Link.ConceptFrom.ConceptModel._id != ConceptModel._id){
                        listConcepts[Link.ConceptFrom._id] = Link.ConceptFrom;
                    }
                }
            }
            if (Link.ConceptTo){
                if (Link.ConceptTo.ConceptModel){
                    if (Link.ConceptTo.ConceptModel._id != ConceptModel._id){
                        listConcepts[Link.ConceptTo._id] = Link.ConceptTo;
                    }
                }
            }
        }

// super concepts

        for (let idConcept in listConcepts){
            let Concept = listConcepts[idConcept];
            for (let posS=0; posS<Concept.SuperConcepts.length;posS++){
                let SuperConcept = Concept.SuperConcepts[posS].Concept;
                if (SuperConcept){
                    listConcepts[SuperConcept._id] = SuperConcept;
                }

            }
        }



        for (let idConcept in listConcepts){
            let Concept = listConcepts[idConcept];
            delete Concept.ConceptModel.Concepts;
            delete Concept.ConceptModel.Links;
            
            let strConcept = JSON.stringify(Concept,null,4);
            zip.file(`concept_${Concept._id}.json`, strConcept);    
        }

        const rdfs = this.state.rdfs;
        zip.file(`conceptmodel_${this.state.idConceptModel}.ttl`,rdfs);

        const zipData = await zip.generateAsync({
            type: "blob",
            streamFiles: true,
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(zipData);
        link.download = `conceptmodel_${this.state.idConceptModel}.zip`;
        link.click();

    }


    makeCsv(){

        if (!this.state.ConceptModel){
            return null;
        }

        if (!this.state.ConceptModel.Concepts){
            return null;
        }
    

        let rows = [];

        for (let posC=0;posC<this.state.ConceptModel.Concepts.length;posC++){
            const Concept = this.state.ConceptModel.Concepts[posC];
            let row = {};
            let rowPrefix = '';
            if (this.state.ConceptModel.prefix){
                rowPrefix = `${this.state.ConceptModel.prefix}:`
            }
            row.Concept = `${rowPrefix}${Concept.name.replace(/ /g,"_")}`;
            row.Label = Concept.name;
            row.Description = HtmlToText(Concept.description);
            row.SuperConcepts = '';
            for (let posS=0;posS<Concept.SuperConcepts.length;posS++){
                const SuperConcept = Concept.SuperConcepts[posS];
                if (SuperConcept.Concept){
                    if (row.SuperConcepts){
                        row.SuperConcepts += `\r\n`;
                    }
                    row.SuperConcepts += `${SuperConcept.Concept.ConceptModel.prefix}:${SuperConcept.Concept.name.replace(/ /g,"_")}`
                }
            }
            rows.push(row);
        }


        rows.sort((a, b) => {
            const ConceptA = a.Concept.toUpperCase();
            const ConceptB = b.Concept.toUpperCase();
            if (ConceptA < ConceptB) {
              return -1;
            }
            if (ConceptA > ConceptB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });


        
        const options = [];

        const csv = json2csv(rows);
        this.setState({csv:csv});

        return true;

    };

    log(){

        if (!this.state.isLoaded){
            return null;
        }

        if (!this.state.ConceptModel){
            return null;
        }

        let arrLog = [];
        for (let pos=0;pos<this.state.ConceptModel.Concepts.length;pos++){
            let Concept = this.state.ConceptModel.Concepts[pos];
            if (Concept.createdAt){
                let LogEntry = {};
                LogEntry.date = Concept.createdAt;
                LogEntry.action = 'created';
                LogEntry.subjectType = 'Concept';
                LogEntry.Subject = Concept;
                LogEntry.name = Concept.name;
                LogEntry.link = `/concept/${Concept._id}`;
                arrLog = this.insertLog(arrLog, LogEntry);
            }
            if (Concept.Revisions){
                for (let posR=0; posR<Concept.Revisions.length;posR++){
                    let Revision = Concept.Revisions[posR];
                    if (Revision.report){
                        let LogEntry = {};
                        LogEntry.date = Revision.createdAt;
                        LogEntry.action = 'revised';
                        LogEntry.subjectType = 'Concept';
                        LogEntry.Subject = Concept;
                        LogEntry.name = Concept.name;
                        LogEntry.link = `/concept/${Concept._id}`;
                        LogEntry.Revision = Revision
                        LogEntry.description = Revision.description;
                        arrLog = this.insertLog(arrLog, LogEntry);
                    }
                }
            }
        }

        for (let pos=0;pos<this.state.ConceptModel.Links.length;pos++){
            let Link = this.state.ConceptModel.Links[pos];
            if (Link.createdAt){
                let LogEntry = {};
                LogEntry.date = Link.createdAt;
                LogEntry.action = 'created';
                LogEntry.subjectType = 'Link';
                LogEntry.Subject = Link;
                LogEntry.name = Link.name;
                LogEntry.link = `/link/${Link._id}`;
                arrLog = this.insertLog(arrLog, LogEntry);
            }
            if (Link.Revisions){
                for (let posR=0; posR<Link.Revisions.length;posR++){
                    let Revision = Link.Revisions[posR];
                    if (Revision.report){
                    let LogEntry = {};
                        LogEntry.date = Revision.createdAt;
                        LogEntry.action = 'revised';
                        LogEntry.subjectType = 'Link';
                        LogEntry.Subject = Link;
                        LogEntry.name = Link.name;
                        LogEntry.link = `/link/${Link._id}`;
                        LogEntry.Revision = Revision
                        LogEntry.description = Revision.description;
                        arrLog = this.insertLog(arrLog, LogEntry);                       
                    }
                }
            }
        }

        if (!arrLog.length){
            return null;
        }

        return (
            <PD.Table>
                <PD.Thead>
                    <PD.Tr>
                        <PD.Th>on</PD.Th>
                        <PD.Th>what</PD.Th>
                        <PD.Th>name</PD.Th>
                        <PD.Th>change</PD.Th>
                        <PD.Th>description</PD.Th>
                    </PD.Tr>
                </PD.Thead>

                <PD.Tbody>
                    {
                        arrLog.map(
                        (entry, pos) => 
                            {
                                return (
                                    <PD.Tr>
                                        <PD.Td>{formatDate(entry.date,'dmy')}</PD.Td>
                                        <PD.Td>{entry.subjectType}</PD.Td>
                                        <PD.Td>
                                            <PD.PdNavLink to={entry.link} >{entry.name}</PD.PdNavLink>
                                        </PD.Td>
                                        <PD.Td>{entry.action}</PD.Td>
                                        <PD.Td>{formatText(truncate(HtmlToText(entry.description), 400))}</PD.Td>
                                    </PD.Tr>
                                )
                            }
                        )
                    }
                </PD.Tbody>
            </PD.Table>

        )


    }

    insertLog(array, entry){

        let boolInserted = false;

        let newArray = [];

        for (let pos=0; pos<array.length;pos++){
            if (!boolInserted){
                if (entry.date < array[pos].date){
                    newArray.push(entry);
                    boolInserted = true;
                }
            }
            newArray.push(array[pos]);
        }

        if (!boolInserted){
            newArray.push(entry);
        }

        return newArray;

    }

    versions(){

        if (!this.state.isLoaded){
            return null;
        }

        if (!this.state.ConceptModel){
            return null;
        }

        let newVersion = false;
        let showAdd = false;

        if (this.state.OfferEdit){
            showAdd = true;
        }
        if (this.state.newVersion){
            showAdd = false;
            newVersion = true;
        }

        return (
            <div>
                {showAdd &&
                    <div style={{display:"flex"}}>
                        <PD.Button name='btnAdd' value='add a Named Version' onClick={this.handleAddVersion}/>
                    </div>
                }
                
                {newVersion &&
                    <Version mode='new' idAbout={this.state.idConceptModel} type='conceptmodel' onActioned={this.VersionOnActioned}/>
                }
                <ListVersions key={this.state.updateToggle} OfferEdit={this.state.OfferEdit} filters={{idAbout:this.state.idConceptModel}} onLoad={this.loadedVersions}/>

            </div>
        )
    }

    VersionOnActioned(){
       this.setState(
        {
            newVersion:false,
            updateToggle: Number(!this.state.updateToggle)
        })
    }

    loadedVersions(count){

        let StateChange = {};

        StateChange.countVersions = 0;
        if (count){
            StateChange.countVersions = count;
        }

        this.setState(StateChange);

    }

    loadedConcepts(count){

        let StateChange = {};

        StateChange.countConcepts = 0;
        if (count){
            StateChange.countConcepts = count;
        }

        this.setState(StateChange);

    }

    loadedLinks(count){

        let StateChange = {};

        StateChange.countLinks = 0;
        if (count){
            StateChange.countLinks = count;
        }

        this.setState(StateChange);

    }



    visualise(){
    
        if (!this.state.ConceptModel){
            return <Buffering/>;
        }


        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };


        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.fit = false;
        vizoptions.width = null;
        vizoptions.height = null;
        vizoptions.zoom = false;
        vizoptions.scale = 1;

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            return(
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                                </td>
                                <td>
                                    <PD.Checkbox label="Super Concepts" name="vizSuperConcepts" value={this.state.Controls.vizSuperConcepts} onChange={this.handleChange}/>                    
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div name="divViz" style={styleDiv} onClick={this.handleVizClick}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };


    setLayoutContent(){

        if (this.context.hasLayout){
            if (this.state.ConceptModel){
                this.context.Layout.setLeftAsConceptModel(this.state.ConceptModel._id);
            }
        }

        return;

    }

    showError(){


        if (this.state.invalidFields){
            return(
                <>
                    {
                        Object.keys(this.state.invalidFields).map(
                        (field, pos) => 
                            {
                                let error = this.state.invalidFields[field].error;
                                return <p>{field}:{error}</p>
                            }
                        )
                    }
                </>
            )
        }

        return "ERROR - Failed to Update";

    }

    render() {

        if (this.state.isUpdated){
            switch (this.state.mode){
                case 'remove':
                    this.props.navigation(this.FallBackNavigation);
                    return null;
                default:
                    break;
            };
        };

        if (this.state.isLoaded) {

//            this.setLayoutContent();

            if (this.state.idVersion){
                return(
                    <ViewVersion idVersion={this.state.idVersion} type='conceptmodel'/>
                )
            }

            switch (this.state.mode){
                case 'new':
                case 'edit':

                    return (
                    <div>

                        <PD.Heading>{this.state.mode} Concept Model</PD.Heading>
    
                        {this.state.isFailed &&
                            <PD.ErrorBox>{this.showError()}</PD.ErrorBox>
                        }

                        <form> 
                            <PD.Input label="Name" width="60" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                            <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>

                            <PD.Input label="Namespace" width="30" name="namespace" value={this.state.FormData.namespace} onChange={this.handleChange}/>
                            <PD.Input label="Default Prefix" width="10" name="prefix" value={this.state.FormData.prefix} onChange={this.handleChange}/>

                            <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                            <PD.Select label="Status" name='status'  onChange={this.handleChange} value={this.state.FormData.status}>
                                {
                                    this.dsd.config.ComponentStatuses.map(
                                        (status, posStatus) => 
                                            {
                                                return(
                                                    <option key={posStatus} value={status}>{status}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>

                            <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave"value="Save" />    
                            <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" />    
                        </form>
    
                    </div>    
                    );
                case 'remove':
                    return (
                        <div>
                            <PD.Heading>{this.state.mode} Concept Model</PD.Heading>

                            {this.state.isFailed &&
                                <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                            }

                            <PD.Button onClick={this.handleSubmit} type="warning" value="confirm remove?" />
                            <ViewConceptModel ConceptModel={this.state.ConceptModel}/>
                        </div>
                    )
                case 'view':
                default:

                    if (!this.state.ConceptModel){
                        return <div/>
                    }

                    this.viz = this.dsd.vizConceptModel({ConceptModel:this.state.ConceptModel}, this.state.Controls, this.vizEngine );

                    return (
                        <div>
                            <div>
                                <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                <PD.Heading>Concept Model:{this.state.ConceptModel.name}</PD.Heading>
                            </div>

                            {this.state.OfferEdit &&
                                <span style={{paddingRight:10}}>
                                        <PD.Button onClick={this.handleMode} type="submit" value="edit" />
                                </span>
                            }

                            <PD.Button onClick={this.handleExport} name="btnExport" value="export" />

                            <ViewConceptModel ConceptModel={this.state.ConceptModel}/>

                            <br/>

                            <PD.Tabs>

                                <div label='Visualise'>
                                    <table>
                                        <tr>
                                            <td style={{verticalAlign: 'top', width: 'auto'}}>
                                                {this.visualise()}
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <div label={`Concepts(${this.state.countConcepts})`}  forceRender={true}>
                                    <>
                                        <div style={{display:"flex"}}>
                                            {this.state.OfferEdit && 
                                                <PD.Button name='btnAddConcept' value='add a new Concept' onClick={this.handleButton}/>
                                            }
                                            <fieldset style={{display:"flex"}}>
                                                <legend>filters</legend>
                                                <PD.Select label='status' name='status'  onChange={this.handleConceptFilters} value={this.state.ConceptFilters.status}>
                                                    {
                                                        this.dsd.config.ConceptStatuses.map(
                                                            (status, posStatus) => 
                                                                {
                                                                    return(
                                                                        <option key={posStatus} value={status}>{status}</option>
                                                                    )
                                                                }
                                                        )
                                                    }
                                                </PD.Select>
        
                                            </fieldset>
                                        </div>
                                        <ListConcepts filters={{idConceptModel:this.state.idConceptModel, ...this.state.ConceptFilters}} onLoad={this.loadedConcepts} />
                                    </>
                                </div>

                                <div label={`Links(${this.state.countLinks})`}  forceRender={true}>
                                    <>
                                        <div style={{display:"flex"}}>
                                            {this.state.OfferEdit && 
                                                <PD.Button name='btnAddLink' value='add a new Link' onClick={this.handleButton}/>
                                            }
                                            <fieldset style={{display:"flex"}}>
                                                <legend>filters</legend>
                                                <PD.Select label='status' name='status'  onChange={this.handleLinkFilters} value={this.state.LinkFilters.status}>
                                                    {
                                                        this.dsd.config.LinkStatuses.map(
                                                            (status, posStatus) => 
                                                                {
                                                                    return(
                                                                        <option key={posStatus} value={status}>{status}</option>
                                                                    )
                                                                }
                                                        )
                                                    }
                                                </PD.Select>
        
                                            </fieldset>
                                        </div>
                                        <ListLinks filters={{idConceptModel:this.state.idConceptModel, ...this.state.LinkFilters}} onLoad={this.loadedLinks} />
                                    </>
                                </div>

                                <div label='Log'>
                                    {this.log()}
                                </div>
                                    
                                <div label={`Versions(${this.state.countVersions})`}  forceRender={true}>
                                    {this.versions()}
                                </div>


                                <div label='Developer'>
                                    <table>
                                        {this.viz &&
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>dot</th>
                                                <td><pre>{this.viz.dot}</pre></td>
                                            </tr>
                                        }
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>csv</th>
                                                <td><pre>{this.state.csv}</pre></td>
                                            </tr>

                                            <tr>
                                                <th style={{verticalAlign:"top"}}>rdfs</th>
                                                <td><pre>{this.state.rdfs}</pre></td>
                                            </tr>

                                    </table>

                                </div>


                                <div label='Metadata'>
                                    <ViewMetadata Data={this.state.ConceptModel} />
                                </div>



                                <div label="used in ...">
                                </div>


                            </PD.Tabs>


                        </div>
                    )
            };
        };

        return <div />;
    };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <ConceptModel {...props} navigation={navigation} location={location} params={params} />;
  }
