import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';

import ListConceptModels from '../components/ListConceptModels';

import dsd from "../classes/clsDSD";
import {HowToText, getQueryStringParams} from '../utils/utils';

import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

class ConceptModels extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            MyFilters:{
                scope:'my',
                status: ''
            },
            PublishedFilters:{
                scope:'published'
            },
            DeprecatedFilters:{
                scope:'my deprecated'
            },    
            toggle: 0
        };

        this.dsd = new dsd(this);

        this.handleButton = this.handleButton.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.loadedMy = this.loadedMy.bind(this);
        this.loadedPublished = this.loadedPublished.bind(this);
        this.loadedDeprecated = this.loadedDeprecated.bind(this);

        this.handleMyFilters = this.handleFilters.bind(this,'MyFilters');
        this.handlePublishedFilters = this.handleFilters.bind(this,'PublishedFilters');
        this.handleDeprecatedFilters = this.handleFilters.bind(this,'DeprecatedFilters');


    }

    componentDidMount(){

        this.setup();
    
    }


    componentDidUpdate(prevProps, prevState){

        if (prevProps != this.props){
            this.setup();
        }

    }
    
    setup(){

        let StateChange = {
            countMy:0,
            countPublished:0,
            countDeprecated:0
        };


        this.setState(StateChange);

        return;

    }

    handleButton(event) {

        switch (event.target.name){
            case 'btnAdd':
                this.props.navigation(`/conceptmodel?mode=new`);
                break;
            default:
                break;          
        };

        event.preventDefault();
      }

    handleFilters(StateVarName, event) {

        if (this.idFilterTimeout) {
            clearTimeout(this.idFilterTimeout);
        }

        let Filters = {};
        if (this.state[StateVarName]){
            Filters = this.state[StateVarName];
        }

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        Filters[FormFieldName] = FormFieldValue;

        switch (FormFieldName){
            case 'search':
                this.idFilterTimeout = setTimeout(this.setFilters, 500, StateVarName, Filters);
                return;
        }

        this.setFilters(StateVarName, Filters);

        return;

    };

    setFilters(StateVarName, Filters){

        var obj  = {}
        obj[StateVarName] = Filters
        this.setState(obj);
        this.setState({"toggle":!this.state.toggle})

        return;

    };


      about(){

        if (this.dsd.model){

            return(
                    <div>                    

                        <div>
                            <div><strong>Concept Models</strong></div>
                            {this.dsd.model.conceptmodel &&
                                this.dsd.AboutText(this.dsd.model.conceptmodel.about)
                            }
                        </div>

                    </div>
                )
        }

        return <div/>

    }



    setLayoutContent(){

        if (this.context.hasLayout){
            
            let Content = {Index:{}};

            this.context.Layout.setLayoutContent(Content)

            localStorage.setItem("dsdContent",JSON.stringify(Content));
            
        }

        return;

    }


    loadedMy(count){

        let StateChange = {};

        StateChange.countMy = 0;
        if (count){
            StateChange.countMy = count;
        }

        this.setState(StateChange);

    }

    loadedPublished(count){

        let StateChange = {};

        StateChange.countPublished = 0;
        if (count){
            StateChange.countPublished = count;
        }

        this.setState(StateChange);

    }

    loadedDeprecated(count){

        let StateChange = {};

        StateChange.countDeprecated = 0;
        if (count){
            StateChange.countDeprecated = count;
        }

        this.setState(StateChange);

    }

    
    render() {

        this.setLayoutContent();

        return (

            <div>
                <PD.Heading>Concept Models</PD.Heading>

                <PD.Tabs>


                    <div label="About">                    
                        {this.about()}
                    </div>

                    {this.dsd.loggedon &&
                        <div label={`My Concept Models(${this.state.countMy})`} forceRender={true}>
                            <div style={{display:"flex"}}>
                                <PD.Button value='add a new Concept Model' name='btnAdd' onClick={this.handleButton}/>
                                <fieldset style={{display:"flex"}}>
                                    <legend>filters</legend>

                                    <PD.Select label='Status' name='status'                                                                         
                                        onChange={this.handleMyFilters} 
                                        value={this.state.MyFilters.status}
                                    >                                        

                                        {
                                            this.dsd.config.ComponentStatuses.map(
                                                (status, posStatus) => 
                                                    {
                                                        return(
                                                            <option key={posStatus} value={status}>{status}</option>
                                                        )
                                                    }
                                            )
                                        }
                                    </PD.Select>

                                    <PD.Input label="Search" width="15" name="search" value={this.state.MyFilters.search} onChange={this.handleMyFilters}/>

                                </fieldset>
                            </div>

                            <ListConceptModels filters={this.state.MyFilters} columns={{status:true}} toggle={this.state.toggle} onLoad={this.loadedMy}/> 

                        </div>
                    }


                    <div label={`Published Concept Models(${this.state.countPublished})`} forceRender={true}>
                        <div style={{display:"flex"}}>
                            <fieldset style={{display:"flex"}}>
                                <legend>filters</legend>
                                <PD.Input label="Search" width="15" name="search" value={this.state.PublishedFilters.search} onChange={this.handlePublishedFilters}/>
                            </fieldset>
                        </div>

                        <ListConceptModels filters={this.state.PublishedFilters} columns={{owner:true}} toggle={this.state.toggle} onLoad={this.loadedPublished}/>

                    </div>

                    {this.dsd.loggedon &&
                        <div label={`Deprecated Concept Models(${this.state.countDeprecated})`} forceRender={true}>
                            <div style={{display:"flex"}}>
                                <fieldset style={{display:"flex"}}>
                                    <legend>filters</legend>
                                    <PD.Input label="Search" width="15" name="search" value={this.state.DeprecatedFilters.search} onChange={this.handleDeprecatedFilters}/>
                                </fieldset>
                            </div>

                            <ListConceptModels filters={this.state.DeprecatedFilters} columns={{}} toggle={this.state.toggle} onLoad={this.loadedDeprecated}/>

                        </div>
                    }


                </PD.Tabs>
            </div>

            )
        };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();

    return <ConceptModels {...props} navigation={navigation} location={location} />;
  }