import React from "react"

import dsd from "../classes/clsDSD";

import * as PD from '../classes/pdStyle';

import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';
import { isNull, isEqual, isArray } from "lodash";


class DesignerContainer extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            LayoutContent:null,
            user:null
        }

        this.dsd = new dsd(this);

        this.setLayoutContent = this.setLayoutContent.bind(this);
        this.setUser = this.setUser.bind(this);

    }


    setLayoutContent(content){

        if (!isEqual(content, this.state.LayoutContent)){
            this.setState({LayoutContent:content});
        }
    }

    setLeftAsConceptModel(id){
        if (id){
            this.setState({
                LeftAs:'ConceptModel',
                isLoaded:false,
                loading:true
            });
            this.dsd.getConceptModel(id);
        }
    }

    setLeftAsLogicalModel(id){
        if (id){
            this.setState({
                LeftAs:'LogicalModel',
                isLoaded:false,
                loading:true
            });
            this.dsd.getLogicalModel(id);
        }
    }

    setLeftAsDataDictionary(id){
        if (id){
            this.setState({
                LeftAs:'DataDictionary',
                isLoaded:false,
                loading:true
            });
            this.dsd.getDataDictionary(id);
        }
    }


    setLeftAsProcess(id){
        if (id){
            this.setState({
                LeftAs:'Process',
                isLoaded:false,
                loading:true
            });
            this.dsd.getProcess(id);
        }
    }


    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        if (!isEqual(this.state, prevState)){
            if (this.state.loading){
                if (this.state.isLoaded){                    
                    this.loadLeftContent();
                    this.setState({
                        loading:false
                    })
                }
            }
        }
    }
    
    loadLeftContent(){

        let Content = {Index:{}};

        switch(this.state.LeftAs){
            case 'ConceptModel':

                if (this.state.ConceptModel){
                    Content.Index.type = 'Concept Model';
                    Content.Index.title = this.state.ConceptModel.name;
                    Content.Index.link = `/conceptmodel/${this.state.ConceptModel._id}`

                    Content.Index.Sections = [];

                    if (this.state.ConceptModel.Concepts){
                        let Section = {title:"Concepts"}
                        Section.Items = [];
                        for (let posI=0;posI<this.state.ConceptModel.Concepts.length; posI++){
                            let Concept = this.state.ConceptModel.Concepts[posI];

                            switch (Concept.status){
                                case 'deprecated':
                                    break;
                                default:

                                    let Item = {}
                                    Item.label = Concept.name;
                                    Item.link = `/concept/${Concept._id}`
                                    Section.Items.push(Item);
                                    break;
                            }
                        }
                        Content.Index.Sections.push(Section);
                    }

                    if (this.state.ConceptModel.Links){
                        let Section = {title:"Links"}
                        Section.Items = [];
                        for (let posI=0;posI<this.state.ConceptModel.Links.length; posI++){
                            let Link = this.state.ConceptModel.Links[posI];

                            switch (Link.status){
                                case 'deprecated':
                                    break;
                                default:

                                    let Item = {}
                                    Item.label = Link.name;
                                    Item.link = `/link/${Link._id}`
                                    Section.Items.push(Item);
                                    break;
                            }
                        }
                        Content.Index.Sections.push(Section);
                    }

                }

                break;
            case 'LogicalModel':

                if (this.state.LogicalModel){
                    Content.Index.type = 'Logical Model';
                    Content.Index.title = this.state.LogicalModel.name;
                    Content.Index.link = `/logicalmodel/${this.state.LogicalModel._id}`

                    Content.Index.Sections = [];

                    if (this.state.LogicalModel.Entities){
                        let Section = {title:"Entities"}
                        Section.Items = [];
                        for (let posI=0;posI<this.state.LogicalModel.Entities.length; posI++){
                            let Entity = this.state.LogicalModel.Entities[posI];

                            switch (Entity.status){
                                case 'deprecated':
                                    break;
                                default:

                                    let Item = {}
                                    Item.label = Entity.name;
                                    Item.link = `/entity/${Entity._id}`
                                    Section.Items.push(Item);
                                    break;
                            }
                        }
                        Content.Index.Sections.push(Section);
                    }

                    if (this.state.LogicalModel.Relationships){
                        let Section = {title:"Relationships"}
                        Section.Items = [];
                        for (let posI=0;posI<this.state.LogicalModel.Relationships.length; posI++){
                            let Relationship = this.state.LogicalModel.Relationships[posI];

                            switch (Relationship.status){
                                case 'deprecated':
                                    break;
                                default:

                                    let Item = {}
                                    Item.label = '-';
                                    if (Relationship.Link){
                                        Item.label = `${Relationship.EntityFrom.name} ${Relationship.Link.label} ${Relationship.EntityTo.name}`;
                                        if (Relationship.inverse){
                                            Item.label = `${Relationship.EntityFrom.name} ${Relationship.Link.inverseLabel} ${Relationship.EntityTo.name}`;
                                        }
                                    }

                                    Item.link = `/relationship/${Relationship._id}`
                                    Section.Items.push(Item);
                                    break;
                            }
                        }
                        Content.Index.Sections.push(Section);
                    }

                }

                break;

            case 'DataDictionary':

                if (this.state.DataDictionary){
                    Content.Index.type = 'Data Dicitonary';
                    Content.Index.title = this.state.DataDictionary.name;
                    Content.Index.link = `/datadictionary/${this.state.DataDictionary._id}`

                    Content.Index.Sections = [];

                    if (this.state.DataDictionary.Structures){
                        let Section = {title:"Structures"}
                        Section.Items = [];
                        for (let posI=0;posI<this.state.DataDictionary.Structures.length; posI++){
                            let Structure = this.state.DataDictionary.Structures[posI];

                            switch (Structure.status){
                                case 'deprecated':
                                    break;
                                default:

                                    let Item = {}
                                    Item.label = Structure.name;
                                    Item.link = `/structure/${Structure._id}`
                                    Section.Items.push(Item);
                                    break;
                            }
                        }
                        Content.Index.Sections.push(Section);
                    }


                    if (this.state.DataDictionary.TermTypes){
                        let Section = {title:"Term Types"}
                        Section.Items = [];
                        for (let posI=0;posI<this.state.DataDictionary.TermTypes.length; posI++){
                            let TermType = this.state.DataDictionary.TermTypes[posI];

                            switch (TermType.status){
                                case 'deprecated':
                                    break;
                                default:

                                    let Item = {}
                                    Item.label = TermType.name;
                                    Item.link = `/termtype/${TermType._id}`
                                    Section.Items.push(Item);
                                    break;
                            }
                        }
                        Content.Index.Sections.push(Section);
                    }
                }

                break;

            case 'Process':

                if (this.state.Process){
                    Content.Index.type = 'Process';

                    this.viz = this.dsd.vizProcess(this.state.Process);

                    Content.Index.title = this.state.Process.name;
                    Content.Index.link = `/process/${this.state.Process._id}`

                    Content.Index.Sections = [];

                    if (this.viz){
                        if (this.viz.nodes){

                            let Section = {title:"Steps"}
                            Section.Items = [];
        

                            Object.keys(this.viz.nodes).map((idNode,posNode)=>{
                                let dotNode = this.viz.nodes[idNode];

                                let Item = {}
                                Item.label = dotNode.label;
                                if (dotNode.Step){
                                    Item.link = `/step/${dotNode.Step._id}`
                                }
                                Section.Items.push(Item);
                            })

                            Content.Index.Sections.push(Section);

                        }
                    }

                }

                break;

        }

        this.setLayoutContent(Content)

        localStorage.setItem("dsdContent",JSON.stringify(Content));

    }


    setUser(user){

        if (!isEqual(user, this.state.user)){
            this.setState({user:user});
        }
    }


    FeatureMenu(){

        if (this.dsd.config.Features){
            return (
                <div className="designer-sidemenu">
                    {this.dsd.config.Features.map(
                        (Feature, posFeature) => 
                        {
                            let classSection = '';
                            if (Feature.highlight){
                                classSection = 'designer-menuHighlight'
                            }
                            return(
                                <p><PD.PdNavLink to={Feature.link}> {Feature.label}</PD.PdNavLink></p>
                            )
                        })
                    }
                </div>
            )
        }
        return null;
        
    }

    IndexLinks(){

        if (this.state.LayoutContent){
            if (this.state.LayoutContent.Index){
                const Index = this.state.LayoutContent.Index;
                if (Index.title){
                    return (
                        <div className="designer-sidemenu">
                            {Index.title &&
                                <div className="designer-indextitle">

                                    {Index.type &&
                                        <h3>{Index.type}</h3>
                                    }

                                    {(() => {
                                        if (Index.link) {
                                            return(
                                            <p><PD.PdNavLink to={Index.link}> {Index.title}</PD.PdNavLink></p>
                                            )
                                        }
                                        else{
                                            return (<p>{Index.title}</p>)
                                        }
                                    })()}

                                    <div style={{paddingLeft: "20px"}}>
                                        {this.state.LayoutContent.Index.Sections &&
                                            this.state.LayoutContent.Index.Sections.map(
                                                (Section, posSection) => 
                                                {
                                                    let classSection = '';
                                                    if (Section.highlight){
                                                        classSection = 'designer-menuHighlight'
                                                    }
                                                    return(
                                                        <div key={posSection} className={classSection}>

                                                            {(() => {
                                                                if (Section.link) {
                                                                    return(
                                                                    <p><PD.PdNavLink to={Section.link}> {Section.title}</PD.PdNavLink></p>
                                                                    )
                                                                }
                                                                else{
                                                                    return (<p>{Section.title}</p>)
                                                                }
                                                            })()}


                                                            {Section.Items &&
                                                                <ul>
                                                                {Section.Items.map(
                                                                    (Item, posItem) => 
                                                                    {

                                                                        let classItem = '';
                                                                        if (Item.highlight){
                                                                            classItem = 'designer-menuHighlight'
                                                                        }

                                                                        return(
                                                                            <li key={posItem} className={classItem}>
                                                                                {(() => {
                                                                                    if (Item.link) {
                                                                                        return(
                                                                                        <PD.PdNavLink to={Item.link}> {Item.label}</PD.PdNavLink>
                                                                                        )
                                                                                    }
                                                                                    else{
                                                                                        return (<>{Item.label}</>)
                                                                                    }
                                                                                })()}
                                                                            </li>
                                                                        )
                                        
                                                                    }
                                                                )
                                                                }
                                                                </ul>
                                                            }

                                                        </div>
                                                    )
                                                }
                                            )
                                        }
                                    </div>
                                </div>
                            }
                            <hr/>

                        </div>
                    )
                }
                                        
                return null;
            }

        }

        return <div/>        
    }

    render(){

        return(
            <DesignerLayoutContext.Provider value={{"hasLayout":true, "Layout":this, "Content":this.state.LayoutContent}}>
                <div id="designer-container">
                    <div id="designer-header" key="designer-header">
                        <DesignerHeader content={this.state.LayoutContent} user={this.state.user}/>
                    </div>
                    <div id="designer-wrapper">
                        <div id="designer-leftpane" key="designer-leftpane">
                            <div style={{paddingLeft:"5px"}}>
                                {this.IndexLinks()}
                                {this.FeatureMenu()}
                            </div>
                        </div>
                        <div id="designer-content" key="designer-content">
                            {this.props.children}
                        </div>
                    </div>
                    <div id="designer-footer"  key="designer-footer">
                        <DesignerFooter/>
                    </div>
                </div>
            </DesignerLayoutContext.Provider>
        )
    };
};


class DesignerHeader extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            breadcrumb:null
        }

    }

    componentDidMount(){

        this.setup();
 
     }
 
     componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }
     }


    setup(){


        if (this.props.content){
            if (this.props.content.Breadcrumb){
                  this.setState({breadcrumb:this.props.content.Breadcrumb})
            }
        }

    }

    Breadcrumb(Breadcrumb){

        if (Breadcrumb.Items){

            return (
                <div className="designer-breadcrumb"> 

                    {Breadcrumb.Items.map(
                        (Item, posItem) => 
                        {
                            return(
                                <>
                                    {(() => {
                                        if (Item.link) {
                                            return(
                                                <PD.PdNavLink key={posItem} to={Item.link}> {Item.label}</PD.PdNavLink>
                                            )
                                        }
                                        else{
                                            return (<span key={posItem}>{Item.label}</span>)
                                        }
                                    })()}
                                    {(posItem + 1) < Breadcrumb.Items.length &&
                                        <span key={`span-${posItem}`}> / </span>
                                    }
                                </>
                            )
                        }
                    )
                    }
                </div>
            )
         }
        
    }


    render() {

        let Title = 'Digital Service Designer';
        if (this.props.content){
            if (this.props.content.title){
                Title = this.props.content.title;
            }
        }

        let user = this.props.user;

        return (
            <div>
                <div className="designer-title">{Title}</div>
                <table>
                    <tbody>
                        <tr>
                            <td valign='top'>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </td>
                            <td valign='top'>
                                <PD.PdNavLink exact to={"/"} >Home</PD.PdNavLink>
                            </td>
                            {user &&
                            <td valign='top'>
                                <PD.PdNavLink exact to={"/login"} >Logout</PD.PdNavLink>
                            </td>            
                            }
                            {user &&
                                <td valign='top'>
                                <PD.P>Logged on as: {user.organisation}</PD.P>
                                </td>            
                            }
                            {!user &&
                                <td>                                
                                    <PD.PdNavLink exact to={"/login"} >Login</PD.PdNavLink> 
                                </td>
                            }

                        </tr>
                    </tbody>
                </table>
                <hr/>
                {this.state.breadcrumb &&
                    this.Breadcrumb(this.state.breadcrumb)
                }
            </div>
        )
    };
};



class DesignerFooter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }

    }


    render() {

        return (
            <div>
                <hr/>
                Digital Service Designer - version 2.3.1
            </div>
        )

    };
};

export default DesignerContainer;