// v2 Feb 23
// v3 May 23
// v4 June 23

// formatdatetime

import { isNull, isEqual, isArray } from "lodash";

//import 'semantic-ui-css/semantic.min.css'
import { Button , Popup } from 'semantic-ui-react'

import sanitizeHtml from 'sanitize-html';

import { htmlToText } from 'html-to-text';




export const getParam = (nameParam, props) => {

    let value = null;

    const queryparams = getQueryStringParams(props.location.search);

    if (queryparams.hasOwnProperty(nameParam)){
        value = queryparams[nameParam];
    }

    if (props.params.hasOwnProperty(nameParam)){
        value = props.params[nameParam];
    }

    if (props.hasOwnProperty(nameParam)){
        value = props[nameParam];
    }

   return value;
};


export const  getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};



export const  cloneObject = object => {

    return JSON.parse(JSON.stringify(object));

};


export const  HowToText = (howtotext, model) => {

    if (!isArray(howtotext)){
        return <span>{howtotext}</span>
    }


    return (
        howtotext.map((block, posBlock) => 
            {
                return (

                    <p>

                        {
                            block.parts.map(
                                (part, posPart) => 
                                    {

                                        if (part.entity){

                                            let Entity = null;
                                            let EntityContent = null;
                                            if (part.entity in model.entities){
                                                Entity = model.entities[part.entity];
                                                if (Entity.definition){
                                                    EntityContent = Entity.definition;
                                                }
                                                if (Entity.howtouse){
                                                    EntityContent = Entity.howtouse;
                                                }
                
                                            }
                                        
                                            return(
                
                                               <Popup
                                                    trigger={<Button>{part.text}</Button>}
                                                    header={Entity.label}                                    
                                                    content={EntityContent}
                                                />                    
                                           )
                                        }

                                        return (
                                            <span>{part.text}</span>
                                        )
                                    }
                            )
                        }



                    </p>
                )
            }
        )
    )

};

export const  hasProperty = (object,property) => {

    if (object.hasOwnProperty(property)){
        if (object[property] !== null ){
            if (object[property].toString().trim() != ''){
                return true
            }
        }
    }

    return false;

};

export const  formatDate = (inDate = null,outFormat = "xsd") => {

    var d;

    if (isNull(inDate)){
        d = new Date();
    } else {
        d = new Date(inDate)
    }

    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear();

    var hours = '' + d.getHours();
    var minutes = '' + d.getMinutes();
    var seconds = '' + d.getSeconds();


    if (month.length < 2){
        month = '0' + month;
    }

    if (day.length < 2){
        day = '0' + day;
    }

    if (hours.length < 2){
        hours = '0' + hours;
    }

    if (minutes.length < 2){
        minutes = '0' + minutes;
    }
    if (seconds.length < 2){
        seconds = '0' + seconds;
    }


    switch (outFormat){
        case 'dmy':
            return day + '/' + month + '/' + year;
        case 'dmy hms':
            return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes + ':' + seconds;   
        case 'xsd':
        default:
            return [year, month, day].join('-');

    }
};




export const  formatText = (inText = null) => {

//    const arrParts = inText.split(/[\r\n]+/);

    if (isNull(inText)){
        return (<div/>)
    }

    const arrParts = inText.split(/[\r\n]/);

    if (arrParts.length < 2){
        return inText;
    }
    return(
    <div>
        {
            arrParts.map(
                (Part, posPart) => 
                {
                    switch (Part){
                        case '':
                            return <br key={posPart}/> 
                            break;
                    }
                    return(
                        <div key={posPart}>{Part}</div>
                    )
                })
        }
    </div>
    )

};

export const  htmlEncode = (rawStr) => {

    var encodedStr = rawStr.replace(/[\u00A0-\u9999<>\&]/g, function(i) {
        return '&#'+i.charCodeAt(0)+';';
     });
     return encodedStr;
};

export const  dotField = (inString, wrap=false, chars=200) => {

    let outString = '';

    switch(wrap){
        case true:
            outString = truncate(inString,chars);
            break;
        default:
            outString = truncate(inString,chars);
    }
    outString = htmlEncode(outString);

    if (wrap){
        outString = wrap(outString);
    }

    return outString;
};

export const  wrap= (Text, LineLength = 40, BreakChars = "<br/>") => {

    let strOut = '';
    let strLine = '';

    let arrSplit = Text.split(' ');
// add spaces where any word is longer than the line length

    let arrWords = [];

    let i = 0;
    for(i = 0; i < arrSplit.length; i++){
        let Word = arrSplit[i];
        while (Word.length > LineLength) {
            arrWords.push(Word.substr(0,LineLength-1));
            Word = Word.substr(LineLength)
        }
        arrWords.push(Word);
    }



    for(i = 0; i < arrWords.length; i++){
        let Word = arrWords[i];
        if ((strLine.length + Word.length) > parseInt(LineLength)){
            if (strLine.length > 0){
                if (strOut.length > 0){
                    strOut += BreakChars;
                }
                strOut += strLine;
                strLine = '';
            }
        }
        if (strLine.length > 0){
            strLine += ' ';
        }
        strLine += Word;
    }
    if (strLine.length > 0){
        if (strOut.length > 0){
            strOut += BreakChars;
        }
        strOut += strLine;
        strLine = '';
    }

    return strOut;
};

export const  truncate= (str, max=50, suffix='...') => {


    if (!str){
        return '';
    };

    if (str.length < max){
        return str;
     }
     
     return `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))} ${suffix}`;

};


//export const sanitize = (dirty, options) => {
//    return (sanitizeHtml(dirty))
//};
  

const htmlSanitizeDefaultOptions = {};
const htmlSanitizeNoTagsOptions = {
    allowedTags: []
};


const htmlsanitize = (dirty, options) => ({
    __html: sanitizeHtml(
      dirty, 
      {options: { ...htmlSanitizeDefaultOptions, ...options }}
    )
  });
  

export  const SanitizeHTML = ({ html, options = {}, ...props}) => (
    
    <div dangerouslySetInnerHTML={htmlsanitize(html, options)}  {...props} />
);

const htmlNoTags = (dirty, options = {}) => {
    let txt = 
    sanitizeHtml(
      dirty, 
      htmlSanitizeNoTagsOptions
    )
    return txt;
};
  

export  const NoTagsHTML = (html) => {
    return htmlNoTags(html).toString();
};



export const HtmlToText = (html) => {
        
    const options = {};
    const text = htmlToText(html, options);

    return (text)
};



export const round = (value,precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
};
