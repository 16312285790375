import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';

import dsd from "../classes/clsDSD";

import { Plus, Trash, ArrowUp, ArrowDown } from 'react-bootstrap-icons';

import { default as Complex } from "../components/FormProperty";

import SelectStructure from "../components/SelectStructure";

class FormProperty extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            FormData:{},
            isLoaded: false,
            isFailed: false
        };


        this.data = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeBoolean = this.handleChangeBoolean.bind(this);

        this.handleButton = this.handleButton.bind(this);
        this.updateParent = this.updateParent.bind(this);


        this.addComplexProperty = this.addComplexProperty.bind(this);
        this.removeComplexProperty = this.removeComplexProperty.bind(this);
        this.updateComplexProperty = this.updateComplexProperty.bind(this);
        this.moveComplexPropertyUp = this.moveComplexPropertyUp.bind(this);
        this.moveComplexPropertyDown = this.moveComplexPropertyDown.bind(this);



        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `.`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};
        

        if (!isEqual(this.state, prevState)){

            if (this.state.Property){
                StateChange.isLoaded = true;
            }

            if (this.state.Entity != prevState.Entity){
                this.setAttribute(this.state.Property.idAttribute);
            }

            if (this.state.Structure != prevState.Structure){
                this.setAttribute(this.state.Property.idAttribute);
            }


            if (this.state.Property != prevState.Property){
                this.setAttribute(this.state.Property.idAttribute);
            }

            this.setState(StateChange);

        }

    }

    
    setAttribute(idAttribute){

        let StateChange = {};

        let Attribute = null;

        if (this.state.FormData.sourceType != 'attribute'){
            return;
        }

        if (this.state.Entity){
            if (this.state.Entity.Attributes){
                for (let posA=0;posA<this.state.Entity.Attributes.length;posA++){
                    if (this.state.Entity.Attributes[posA]._id == idAttribute){
                        Attribute = this.state.Entity.Attributes[posA];
                    }
                }
            }
        }

        if (this.state.Structure){
            if (this.state.Structure.Attributes){
                for (let posA=0;posA<this.state.Structure.Attributes.length;posA++){
                    if (this.state.Structure.Attributes[posA]._id == idAttribute){
                        Attribute = this.state.Structure.Attributes[posA];
                    }
                }
            }
        }


        StateChange.Attribute = Attribute;

        if (Attribute){
            let FormData = this.state.FormData;

            FormData.complex = false;
            StateChange.optionsComplex = [{value:false,label:'no'}]
            if (Attribute.idStructure){
                FormData.complex = true;
                delete FormData.dataType;
                StateChange.optionsComplex = [{value:true,label:'yes'}]
            }
            else{
                if (Attribute.dataType){
                    if (Attribute.dataType in this.dsd.config.DataTypes){
                        FormData.dataType = Attribute.dataType;
                        StateChange.optionsDataType = {}
                        StateChange.optionsDataType[Attribute.dataType] = this.dsd.config.DataTypes[Attribute.dataType];
                    }
                }
            }

            if (FormData.occurs == ''){
                FormData.occurs = Attribute.occurs;
            }
            switch (Attribute.occurs){
                case 'once':
                    StateChange.optionsOccurs = ['once'];
                    break;
            }

            if (FormData.required == ''){
                FormData.required = Attribute.required;
            }
            switch (Attribute.required){
                case 'yes':
                    StateChange.optionsRequired = ['yes'];
                    break;
            }

            StateChange.FormData = FormData;
        }
        this.setState(StateChange);

    }

    setup(){

        let StateChange = {

            isLoaded:false,

            FormData: {},

            Property: null,
            seq: null,

            onAdd: null,
            onRemove: null,
            onUpdate: null,
            onMoveUp: null,
            onMoveDown: null,

            parentSourceType : null,
            idEntity : null,
            idRelationship: null,

            Entity : null,
            Relationship: null,
            Structure: null,
            ChildStructure: null,

            optionsComplex : ['x'],
            optionsDataType: {},
            optionsOccurs : [],
            optionsRequired : []

        }

        StateChange.onAdd = getParam('onAdd', this.props);
        StateChange.onRemove = getParam('onRemove', this.props);
        StateChange.onUpdate = getParam('onUpdate', this.props);
        StateChange.onMoveUp = getParam('onMoveUp', this.props);
        StateChange.onMoveDown = getParam('onMoveDown', this.props);

        StateChange.mode = getParam('mode', this.props);

        StateChange.Property = getParam('Property', this.props);
        if (isNull(StateChange.Property)){
            StateChange.Property = {};
        }
        StateChange.FormData = StateChange.Property;

        StateChange.seq = getParam('seq', this.props);

        if (StateChange.mode == null){
            StateChange.mode = 'edit';
        }

        StateChange.parentSourceType = getParam('parentSourceType', this.props);
        StateChange.idEntity = getParam('idEntity', this.props);
        StateChange.idRelationship = getParam('idRelationship', this.props);
        StateChange.Structure = getParam('Structure', this.props);


        if (StateChange.idEntity){
            this.dsd.getEntity(StateChange.idEntity);
        }
        if (StateChange.idRelationship){
            this.dsd.getRelationship(StateChange.idRelationship);
        }

        this.setState(StateChange);

        let SourceType = null;
        if (StateChange.Property){
            if (StateChange.Property.sourceType){
                SourceType = StateChange.Property.sourceType;
            }
            if (StateChange.Property.idStructure){
                this.loadChildStructure(StateChange.Property.idStructure);
            }
        }
        this.setOptions(SourceType);

    }

    loadChildStructure(idStructure){
        if (idStructure){
            this.dsd.getStructure(idStructure,'ChildStructure');
        }

        return;
    }

    setOptions(SourceType = null){

        let StateChange = {}

        StateChange.optionsComplex = [{value:true,label:'yes'},{value:false,label:'no'}];
        StateChange.optionsDataType = this.dsd.config.DataTypes;
        StateChange.optionsRequired = this.dsd.config.Required;
        StateChange.optionsOccurs = this.dsd.config.Occurs;

        let FormData = this.state.FormData;

        switch (SourceType){
            case 'attribute':
                break;
            case 'structure':
                StateChange.optionsComplex = [{value:true,label:'yes'}]
                FormData.complex = true;
                break;
            default:
                break;
        }

        this.setState(StateChange);

    }


    handleButton(event) {
    
        let StateChange = {};

        switch (event.currentTarget.name){
            case 'btnAdd':
                StateChange.mode = 'edit';

                if (this.state.onAdd){
                    this.state.onAdd();
                    break;
                }
                break;
            case 'btnDelete':

                if (this.state.onRemove){
                    this.state.onRemove(this.state.seq);
                    break;
                }

                break;

            case 'btnUp':

                if (this.state.onMoveUp){
                    this.state.onMoveUp(this.state.seq);
                    break;
                }

                break;

            case 'btnDown':

                if (this.state.onMoveDown){
                    this.state.onMoveDown(this.state.seq);
                    break;
                }

                break;


    
        }
    
        this.setState(StateChange);
    
        event.preventDefault();    

    };


    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;

        this.setState({"FormData": FormData});

        switch (FormFieldName){
            case 'idAttribute':
                this.setAttribute(FormFieldValue)
                break;                
            case 'sourceType':
                this.setOptions(FormFieldValue);
                switch (FormFieldValue){
                    case 'attribute':
                        this.setAttribute(this.state.FormData.idAttribute);
                        break;
                };
                break;
            case 'idStructure':
                this.loadChildStructure(FormFieldValue);
                break;    
        }
    };


    handleChangeBoolean(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        switch (FormFieldValue){
            case 'true':
                FormFieldValue = true;
                break;
            case 'false':
                FormFieldValue = false;
                break;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;

        this.setState({"FormData": FormData});

    };


    updateParent(){    

        if (this.state.onUpdate){
            this.state.onUpdate(this.state.seq, this.state.FormData);
            return;
        }
    }


    addComplexProperty(){
        let FormData = this.state.FormData;

        if (!('Properties' in FormData)){
            FormData['Properties'] = [];
        }
        if (!FormData.Properties){
            FormData['Properties'] = [];
        }

        FormData.Properties.push({});
        this.setState({"FormData": FormData});


    }

    removeComplexProperty(seq){

        let FormData = this.state.FormData;

        if (!('Properties' in FormData)){
            FormData['Properties'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Properties.length;pos++){
            let item = FormData.Properties[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Properties = arrNew;
        this.setState({"FormData": FormData});

        this.updateParent();

    }


    moveComplexPropertyUp(seq){

        let FormData = this.state.FormData;

        if (!('Properties' in FormData)){
            FormData['Properties'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Properties.length;pos++){
            let item = FormData.Properties[pos];
            if (pos == (seq-2)){
                item = FormData.Properties[pos+1];
            }
            if (pos == (seq-1)){
                item = FormData.Properties[pos-1];
            }
            arrNew.push(item); 
        }

        FormData.Properties = arrNew;
        this.setState({"FormData": FormData});

    }

    moveComplexPropertyDown(seq){

        let FormData = this.state.FormData;

        if (!('Properties' in FormData)){
            FormData['Properties'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Properties.length;pos++){
            let item = FormData.Properties[pos];
            if (pos == (seq-1)){
                item = FormData.Properties[pos+1];
            }
            if (pos == (seq)){
                item = FormData.Properties[pos-1];
            }
            arrNew.push(item); 
        }

        FormData.Properties = arrNew;
        this.setState({"FormData": FormData});

    }


    updateComplexProperty(seq, Property){


        if ('description' in Property){
            Property.description = sanitizeHtml(Property.description);
        }


        let FormData = this.state.FormData;

        if (!('Properties' in FormData)){
            FormData['Properties'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Properties.length;pos++){
            let item = FormData.Properties[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Property;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Property);
        }

        FormData.Properties = arrNew;
        this.setState({"FormData": FormData});

        this.updateParent();

    }



    render() {

        if (this.state.isFailed) {
            return <PD.ErrorBox>ERROR - Failed to Load</PD.ErrorBox>
        }


        if (this.state.isLoaded) {

            let Attributes = null;

            if (this.state.Structure){
                if (this.state.Structure.Attributes){
                    Attributes = this.state.Structure.Attributes;
                }
            }


            if (this.state.Entity){
                if (this.state.Entity.Attributes){
                    Attributes = this.state.Entity.Attributes;
                }
            }


            switch (this.state.mode){
                case 'new':
                case 'edit':
                    return (
                        <form>
                            <PD.Table type="list">
                                <PD.Tr>
                                    <PD.Td>
                                        {(() => {
                                            switch (this.state.mode) {
                                                case "new": 
                                                    return <button name="btnAdd" onClick={this.handleButton}><Plus/></button>;
                                                case "edit": 
                                                    return(
                                                        <div>
                                                            <div>
                                                                <button name="btnDelete" onClick={this.handleButton}><Trash/></button>
                                                            </div>
                                                            {this.state.onMoveUp &&
                                                                <div>
                                                                    <button name="btnUp" onClick={this.handleButton}><ArrowUp/></button>
                                                                </div>
                                                            }
                                                            {this.state.onMoveDown &&
                                                                <div>
                                                                    <button name="btnDown" onClick={this.handleButton}><ArrowDown/></button>
                                                                </div>
                                                            }
                                                        </div>
                                                    );
                                            }
                                        })()}
                                    </PD.Td>
                                    {this.state.mode == 'edit' &&
                                        <React.Fragment>
                                            <PD.Td>
                                                <PD.Input label="Name" width="20" name="name" value={this.state.FormData.name} onChange={this.handleChange} onBlur={this.updateParent}/>                                        
                                            </PD.Td>

                                            <PD.Td>
                                                <PD.Radio label="Source Type" name="sourceType" value={this.state.FormData.sourceType} onChange={this.handleChange} onBlur={this.updateParent}>
                                                    {this.state.parentSourceType=='entity' && this.state.idEntity &&
                                                        <label>attribute</label>
                                                    }
                                                    {this.state.parentSourceType=='structure' && this.state.Structure &&
                                                        <label>attribute</label>
                                                    }
                                                    <label>structure</label>
                                                    <label>unmodeled</label>
                                                </PD.Radio>                                        
                                            </PD.Td>


                                            {this.state.FormData.sourceType=='attribute' && Attributes &&
                                                <fieldset>
                                                    <legend>Attribute</legend>  

                                                    <PD.Select label='Attribute' name='idAttribute'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.idAttribute}>
                                                        {
                                                            Attributes.map(
                                                                (Attribute, pos) => 
                                                                    {
                                                                        return(
                                                                            <option key={pos} value={Attribute._id}>{Attribute.name}</option>
                                                                        )
                                                                    }
                                                            )
                                                        }
                                                    </PD.Select>

                                            </fieldset>
                                            }

                                            {this.state.FormData.sourceType=='structure' &&
                                                <fieldset>
                                                    <legend>Structure</legend>  
                                                    <SelectStructure label='Structure' name="idStructure" onChange={this.handleChange} onBlur={this.updateParent}  selectDataDictionary={true} value={this.state.FormData.idStructure}/>
                                                </fieldset>
                                            }



                                            <PD.Td>

                                                {(() => {
                                                    switch (this.state.optionsComplex.length){
                                                        case 0:
                                                            return null;
                                                        case 1:
                                                            return(
                                                                <>
                                                                    <label>Complex?:</label>
                                                                    {this.state.optionsComplex[0].label}
                                                                </>
                                                            )
                                                        default:
                                                            return(

                                                                <PD.Radio label="Complex?" name="complex" value={this.state.FormData.complex} onChange={this.handleChangeBoolean} onBlur={this.updateParent}>
                                                                    {
                                                                        this.state.optionsComplex.map(
                                                                            (option, pos) => 
                                                                                {
                                                                                    return(
                                                                                        <label key={pos} value={option.value}>{option.label}</label>
                                                                                    )
                                                                                }
                                                                        )
                                                                    }
                                                                </PD.Radio>         

                                                            )
                                                        
                                                    }

                                                })()}

                                            </PD.Td>


                                            <PD.Td>
                                                {(() => {
                                                    switch (this.state.FormData.complex) {
                                                        case true:
                                                            break;
                                                        case false:
                                                        default:
                                                            switch (Object.keys(this.state.optionsDataType).length){
                                                                case 0:
                                                                    return null;
                                                                case 1:
                                                                    return(
                                                                        <>
                                                                            <label>Data Type:</label>
                                                                            {this.state.FormData.dataType}
                                                                        </>
                                                                    )
                                                                default:
                                                                    return(
                                                                        <PD.Select label='Data Type' name='dataType'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.dataType}>
                                                                            {
                                                                                Object.keys(this.state.optionsDataType).map(
                                                                                    (datatype, pos) => 
                                                                                        {
                                                                                            return(
                                                                                                <option key={pos} value={datatype}>{datatype}</option>
                                                                                            )
                                                                                        }
                                                                                )
                                                                            }
                                                                        </PD.Select>
                                                            
                                                                    )
                                                            }
                                                    }
                                                })()}
                                            </PD.Td>

                                            <PD.Td>
                                                {(() => {
                                                    switch (this.state.optionsOccurs.length){
                                                        case 0:
                                                            return null;
                                                        case 1:
                                                            return(
                                                                <>
                                                                    <label>Occurs:</label>
                                                                    {this.state.FormData.occurs}
                                                                </>
                                                            )
                                                        default:
                                                            return(
                                                                <PD.Select label='Occurs' name='occurs'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.occurs}>
                                                                    {
                                                                        this.state.optionsOccurs.map(
                                                                            (occurs, pos) => 
                                                                                {
                                                                                    return(
                                                                                        <option key={pos} value={occurs}>{occurs}</option>
                                                                                    )
                                                                                }
                                                                        )
                                                                    }
                                                                </PD.Select>
                                                            )
                                                    }
                                                })()}

                                            </PD.Td>

                                            <PD.Td>

                                                {(() => {
                                                    switch (this.state.optionsRequired.length){
                                                        case 0:
                                                            return null;
                                                        case 1:
                                                            return(
                                                                <>
                                                                    <label>Required:</label>
                                                                    {this.state.FormData.required}
                                                                </>
                                                            )
                                                        default:
                                                            return(

                                                                <PD.Select label='Required' name='required'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.required}>
                                                                    {
                                                                        this.state.optionsRequired.map(
                                                                            (required, pos) => 
                                                                                {
                                                                                    return(
                                                                                        <option key={pos} value={required}>{required}</option>
                                                                                    )
                                                                                }
                                                                        )
                                                                    }
                                                                </PD.Select>
                                                            )
                                                    }
                                                })()}

                                            </PD.Td>


                                        </React.Fragment>
                                    }
                                </PD.Tr>

                                {this.state.mode == "edit" &&
                                    <PD.Tr>
                                        <PD.Td/>
                                        <PD.Td colSpan={4}>
                                            <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}  onBlur={this.updateParent}/>
                                        </PD.Td>
                                    </PD.Tr>
                                }

                            </PD.Table>

                            {this.state.mode == "edit" && this.state.FormData.complex &&
                                <div style={{marginLeft:"30px"}}>
                                    {this.FormComplexProperties()}
                                </div>
                            }   
                        </form>


                    );
                    break;
            };
        };

        return null;
    };


    FormComplexProperties(){

        let seqNext = 1;
        if (this.state.FormData.Properties){
            seqNext = this.state.FormData.Properties.length + 1;
        }
        return (
            <fieldset>
                <legend>Complex Properties</legend>
                {this.state.FormData.Properties &&
                    this.state.FormData.Properties.map(
                        (Property, posProperty) => 
                        {
                            let seq = posProperty+1;
                            return(
                                this.FormComplexProperty('edit', seq, Property)
                            )
                        }
                    )
                }
                {this.FormComplexProperty('new', seqNext)}
            </fieldset>
        );

    }

    FormComplexProperty(mode, seq, Property = {}){
        let Structure = null;

        switch (this.state.FormData.sourceType){
            case 'attribute':
                if (this.state.Attribute){
                    if (this.state.Attribute.Structure){
                        Structure = this.state.Attribute.Structure;
                    }
                }
                break;
            case 'structure':
                if (this.state.ChildStructure){
                    Structure = this.state.ChildStructure;
                }
        }

        let onMoveUp = null;
        if (seq>1) {
            onMoveUp = this.moveComplexPropertyUp;
        }

        let onMoveDown = null;
        if (this.state.FormData.Properties){
            if (seq<this.state.FormData.Properties.length) {
                onMoveDown = this.moveComplexPropertyDown;
            }
        }

        return(
            <div>
                <Complex 
                    key={seq.toString()}
                    parentSourceType="structure"
                    Structure={Structure}
                    mode={mode}
                    parent={this}
                    onAdd={this.addComplexProperty}
                    onRemove={this.removeComplexProperty}
                    onUpdate={this.updateComplexProperty}
                    {...(onMoveUp && { onMoveUp:onMoveUp})}
                    {...(onMoveDown && { onMoveDown:onMoveDown})}
                    Property={Property}
                    seq={seq}/>
            </div>
        )
    }


};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <FormProperty {...props} navigation={navigation} location={location} params={params} />;
  }
