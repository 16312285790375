import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';


import * as PD from '../classes/pdStyle';
import {HtmlToText, cloneObject, getParam} from '../utils/utils';
import {formatDate, formatText, truncate} from '../utils/utils';


import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewDataSpec from "../components/ViewDataSpec";
import ViewMetadata from "../components/ViewMetadata";

import ListClasses from "../components/ListClasses";

import FormElement from "../components/FormElement";
import TreeSerialisation from "../components/TreeSerialisation";


import Version from "../components/Version";
import ListVersions from "../components/ListVersions";
import ViewVersion from "../components/ViewVersion";


import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

import Buffering from "../components/Buffering";

import { json2csv } from 'json-2-csv';

import JSZip from "jszip";


class DataSpec extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            loadData:false
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "dot";


//        this.addElement = this.addElement.bind(this);
//        this.removeElement = this.removeElement.bind(this);
        this.updateElement = this.updateElement.bind(this);


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVizClick = this.handleVizClick.bind(this);

        this.handleExport = this.handleExport.bind(this);

        this.loadedClasses = this.loadedClasses.bind(this);


        this.handleAddVersion = this.handleAddVersion.bind(this);
        this.VersionOnActioned = this.VersionOnActioned.bind(this);
        this.loadedVersions = this.loadedVersions.bind(this);





        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/dataspecs`;


    }

    componentDidMount(){

       this.setup();

    }


    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.vizNodeId){
                let partsVizNodeId = this.state.vizNodeId.split('_');
                switch(partsVizNodeId[0]){
                    case 'concept':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/concept/${partsVizNodeId[1]}`);
                        }
                        break;
                    case 'entity':
                        if (partsVizNodeId.length > 3){
                            switch(partsVizNodeId[2]){
                                case 'structure':
                                    this.props.navigation(`/structure/${partsVizNodeId[3]}`);
                                    break;
                            }
                        }else{
                            if (partsVizNodeId.length > 1){
                                this.props.navigation(`/entity/${partsVizNodeId[1]}`);
                            }
                        }
                        break;
                    case 'structure':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/structure/${partsVizNodeId[1]}`);
                        }
                        break;
        
                }

            }


            if (this.state.mode === 'new'){
                StateChange.isLoaded = true;
            }

            if (this.state.loadData){
                this.loadData(this.state.idDataSpec);
                StateChange.updateToggle = Number(!this.state.updateToggle)
                StateChange.loadData = false;
            }

            if (this.state.isUpdated){
                StateChange.isUpdated = false;
                StateChange.mode = 'view';
                StateChange.loadData = true;
            }
            else{
                if (this.state.idDataSpec != prevState.idDataSpec){
                    if (this.state.idDataSpec){
                        this.onUpdateNavigation = `/dataspec/${this.state.idDataSpec}`;
                        StateChange.loadData = true;
                    }
                }
            }

            if (this.state.isLoaded) {
    
                StateChange.OfferEdit = false;
    
                if (this.state.DataSpec){
                    StateChange.FormData = this.state.DataSpec;
                    if (this.dsd.loggedon){
                        if (this.dsd.user.id == this.state.DataSpec.idUser){
                            StateChange.OfferEdit = true;
                        }
                    }
                    StateChange.xsd = this.dsd.makeDataSpecXsd(this.state.DataSpec);        
                    StateChange.jsonSchema = JSON.stringify(this.dsd.makeDataSpecJsonSchema(this.state.DataSpec), null, "\t");        

                }
            }


            if (this.state.idDataSpec){
                for (var i in window.Breadcrumb){
                    if (window.Breadcrumb[i][0] === 'dataspec'){
                        window.Breadcrumb = window.Breadcrumb.slice(0,i);
                        break;
                    }
                }
                this.Breadcrumb = JSON.parse(JSON.stringify(window.Breadcrumb));
    
                window.Breadcrumb.push(['data spec',`/dataspec/${this.state.idDataSpec}`]);

                this.FallBackNavigation = `../dataspecs`;
                this.onUpdateNavigation = this.FallBackNavigation;
    
            };

            this.setState(StateChange);

        }

    }
    
    loadData(id){

        if (id !== undefined){
            this.dsd.getDataSpec(id);
        }
        
        return;
        
    };

    setup(){


        let StateChange = {
            id: null,
            isLoaded: false,
            isUpdated: false,
            isFailed: false,
            loadData: false,

            OfferEdit: false,

            FormData: {},
            Controls: {
                vizFullSize :true,
                vizStructures: false
            },


            countClasses : 0,


            DataSpec: null,

            idVersion: null,

            action: null,

            updateToggle: 0,

            countVersions: 0,

            vizNodeId: null,

            csv: null,
            rdfs: {},
            xsd:{},
            jsonSchema:{}

        }

        StateChange.mode = getParam('mode', this.props);
        StateChange.idDataSpec = getParam('id', this.props);
        if (StateChange.idDataSpec){
            StateChange.loadData = true;
        }

        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        StateChange.idVersion = getParam('idVersion', this.props);

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let Controls = this.state.Controls;
        switch (FormFieldName){
            case 'vizFullSize':
            case 'vizStructures':
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        
                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    


    handleSubmit(event) {

        switch (event.target.name){
            case 'btnCancel':
                switch (this.state.mode){
                    case 'new':
                        this.props.navigation(this.FallBackNavigation);
                        break;
                    case 'edit':
                        this.loadData(this.state.idDataSpec);
                        this.setState({mode: 'view'});
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };

                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false,
                    invalidFields: false
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addDataSpec(this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editDataSpec(this.state.idDataSpec,this.state.FormData);
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };
    
        };

        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
            case 'btnAddClass':
                this.props.navigation(`/dataspec/${this.state.idDataSpec}/class?mode=new`);
                break;
        }

        this.setState(StateChange);

        event.preventDefault();
    };

    handleAddVersion(event) {

        this.setState({newVersion:true});

    }


    handleVizClick(event) {

        let StateChange = {};

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }
        

        StateChange.vizNodeId = idNode;

        this.setState(StateChange);

        event.preventDefault();

    };


    async handleExport(event, Version = null){

        const zip = new JSZip();
        
        let DataSpec = cloneObject(this.state.DataSpec);

        let strDataSpec = JSON.stringify(DataSpec, null, 4);

        zip.file(`dataspec_${DataSpec._id}.json`, strDataSpec);

        let vizDataSpec = this.dsd.vizSerialisation({Serialisation:DataSpec.Serialisation});
        zip.file(`dataspec_${DataSpec._id}.dot`,vizDataSpec.dot);

        let listClasses = {};
        for (let posC=0; posC<DataSpec.Classes.length; posC++){
            let Class = cloneObject(DataSpec.Classes[posC]);

            Class.DataSpec = DataSpec;
            listClasses[Class._id] = Class;

            let dotClass = this.dsd.vizClass=({Class:Class}).dot;
            zip.file(`class_${Class._id}.dot`,dotClass);

        }

        for (let idClass in listClasses){
            let Class = listClasses[idClass];
            delete Class.DataSpec.Classes;
            delete Class.Serialisation;
            
            let strClass = JSON.stringify(Class,null,4);
            zip.file(`class_${Class._id}.json`, strClass);
        }


        const JsonSchema = this.dsd.makeDataSpecJsonSchema(DataSpec);
        zip.file(`dataspec_${DataSpec._id}/jsonschema`,JSON.stringify(JsonSchema, null, 4));



        const zipData = await zip.generateAsync({
            type: "blob",
            streamFiles: true,
        })

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(zipData);
        link.download = `dataspec_${DataSpec._id}.zip`;
        link.click();

    }


    log(){

        if (!this.state.isLoaded){
            return null;
        }

        if (!this.state.DataSpec){
            return null;
        }

        let arrLog = [];

        if (!arrLog.length){
            return null;
        }

        return (
            <PD.Table>
                <PD.Thead>
                    <PD.Tr>
                        <PD.Th>on</PD.Th>
                        <PD.Th>what</PD.Th>
                        <PD.Th>name</PD.Th>
                        <PD.Th>change</PD.Th>
                        <PD.Th>description</PD.Th>
                    </PD.Tr>
                </PD.Thead>

                <PD.Tbody>
                    {
                        arrLog.map(
                        (entry, pos) => 
                            {
                                return (
                                    <PD.Tr>
                                        <PD.Td>{formatDate(entry.date,'dmy')}</PD.Td>
                                        <PD.Td>{entry.subjectType}</PD.Td>
                                        <PD.Td>
                                            <PD.PdNavLink to={entry.link} >{entry.name}</PD.PdNavLink>
                                        </PD.Td>
                                        <PD.Td>{entry.action}</PD.Td>
                                        <PD.Td>{formatText(truncate(HtmlToText(entry.description), 400))}</PD.Td>
                                    </PD.Tr>
                                )
                            }
                        )
                    }
                </PD.Tbody>
            </PD.Table>

        )


    }

    insertLog(array, entry){

        let boolInserted = false;

        let newArray = [];

        for (let pos=0; pos<array.length;pos++){
            if (!boolInserted){
                if (entry.date < array[pos].date){
                    newArray.push(entry);
                    boolInserted = true;
                }
            }
            newArray.push(array[pos]);
        }

        if (!boolInserted){
            newArray.push(entry);
        }

        return newArray;

    }


    versions(){

        if (!this.state.isLoaded){
            return null;
        }

        if (!this.state.DataSpec){
            return null;
        }

        let newVersion = false;
        let showAdd = false;

        if (this.state.OfferEdit){
            showAdd = true;
        }
        if (this.state.newVersion){
            showAdd = false;
            newVersion = true;
        }

        return (
            <div>
                {showAdd &&
                    <div style={{display:"flex"}}>
                        <PD.Button name='btnAdd' value='add a Named Version' onClick={this.handleAddVersion}/>
                    </div>
                }
                
                {newVersion &&
                    <Version mode='new' idAbout={this.state.idDataSpec} type='dataspec' onActioned={this.VersionOnActioned}/>
                }
                <ListVersions key={this.state.updateToggle} OfferEdit={this.state.OfferEdit} filters={{idAbout:this.state.idDataSpec}} onLoad={this.loadedVersions}/>

            </div>
        )
    }

    VersionOnActioned(){
       this.setState(
        {
            newVersion:false,
            updateToggle: Number(!this.state.updateToggle)
        })
    }

    loadedVersions(count){

        let StateChange = {};

        StateChange.countVersions = 0;
        if (count){
            StateChange.countVersions = count;
        }

        this.setState(StateChange);

    }

    loadedClasses(count){

        let StateChange = {};

        StateChange.countClasses = 0;
        if (count){
            StateChange.countClasses = count;
        }

        this.setState(StateChange);

    }


    visualise(){
    
        if (!this.state.DataSpec){
            return <Buffering/>;
        }

        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };


        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.fit = false;
        vizoptions.width = null;
        vizoptions.height = null;
        vizoptions.zoom = false;
        vizoptions.scale = 1;

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            return(
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div name="divViz" style={styleDiv} onClick={this.handleVizClick}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };


    setLayoutContent(){

        if (this.context.hasLayout){
            
            let Content = {Index:{}};

            if (this.state.DataSpec){
                Content.Index.title = this.state.DataSpec.name;
                Content.Index.link = `/dataspec/${this.state.DataSpec._id}`

                Content.Index.Sections = [];

                if (this.state.DataSpec.Classes){
                    let Section = {title:"Classes"}
                    Section.Items = [];
                    for (let posI=0;posI<this.state.DataSpec.Classes.length; posI++){
                        let Class = this.state.DataSpec.Classes[posI];

                        switch (Class.status){
                            case 'deprecated':
                                break;
                            default:

                                let Item = {}
                                Item.label = Class.name;
                                Item.link = `/class/${Class._id}`
                                Section.Items.push(Item);
                                break;
                        }
                    }
                    Content.Index.Sections.push(Section);
                }



            }
            this.context.Layout.setLayoutContent(Content)

            localStorage.setItem("dsdContent",JSON.stringify(Content));
            

        }

        return;

    }


    showError(){


        if (this.state.invalidFields){
            return(
                <>
                    {
                        Object.keys(this.state.invalidFields).map(
                        (field, pos) => 
                            {
                                let error = this.state.invalidFields[field].error;
                                return <p>{field}:{error}</p>
                            }
                        )
                    }
                </>
            )
        }

        return "ERROR - Failed to Update";

    }


    render() {


        if (this.state.isUpdated){
            switch (this.state.mode){
                case 'remove':
                    this.props.navigation(this.FallBackNavigation);
                    return null;
                default:
                    break;
            };
        };

        if (this.state.isLoaded) {

            this.setLayoutContent();

            if (this.state.idVersion){
                return(
                    <ViewVersion idVersion={this.state.idVersion} type='dataspec'/>
                )
            }

            switch (this.state.mode){
                case 'new':
                case 'edit':

                    return (
                    <div>

                        <PD.Heading>{this.state.mode} Data Spec</PD.Heading>
    
                        {this.state.isFailed &&
                            <PD.ErrorBox>{this.showError()}</PD.ErrorBox>
                        }
    
                        <form> 
                            <PD.Input label="Name" width="60" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                            <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>

                            <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                            <PD.Select label="Status" name='status'  onChange={this.handleChange} value={this.state.FormData.status}>
                                {
                                    this.dsd.config.ComponentStatuses.map(
                                        (status, posStatus) => 
                                            {
                                                return(
                                                    <option key={posStatus} value={status}>{status}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>


                            <fieldset>
                                <legend>Root</legend>                              
                                {this.FormRootElement()}
                            </fieldset>


                            <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave"value="Save" />    
                            <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" />    
                        </form>
    
                    </div>    
                    );
                case 'remove':
                    return (
                        <div>
                            <PD.Heading>{this.state.mode} Data Spec</PD.Heading>

                            {this.state.isFailed &&
                                <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                            }

                            <PD.Button onClick={this.handleSubmit} type="warning" value="confirm remove?" />
                            <ViewDataSpec json={this.state.DataSpec}/>
                        </div>
                    )
                case 'view':
                default:

                    if (!this.state.DataSpec){
                        return <div/>
                    }
                    
//                    const countItems = this.state.countItems == null ? "Loading..." : this.state.countItems;

                    this.viz = this.dsd.vizSerialisation({Serialisation:this.state.DataSpec.Serialisation}, this.state.Controls, this.vizEngine );

                    return (
                        <div>
                            <div>
                                <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                <PD.Heading>Data Spec:{this.state.DataSpec.name}</PD.Heading>
                            </div>

                            {this.state.OfferEdit &&
                                <span style={{paddingRight:10}}>
                                        <PD.Button onClick={this.handleMode} type="submit" value="edit" />                        
                                </span>
                            }

                            <PD.Button onClick={this.handleExport} name="btnExport" value="export" />

                            <ViewDataSpec DataSpec={this.state.DataSpec}/>

                            <br/>

                            <PD.Tabs>

                                <div label='Visualise'>
                                    <table>
                                        <tr>
                                            <td style={{verticalAlign: 'top', width: 'auto'}}>
                                                {this.visualise()}
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <div label={`Classes(${this.state.countClasses})`}  forceRender={true}>
                                    <div style={{display:"flex"}}>
                                        {this.state.OfferEdit && 
                                            <PD.Button name='btnAddClass' value='add a new Class' onClick={this.handleButton}/>
                                        }
                                    </div>

                                    <ListClasses filters={{idDataSpec:this.state.idDataSpec, ...this.state.ClassFilters}} onLoad={this.loadedClasses} />

                                </div>

                                <div label='Serialisation'>
                                    <TreeSerialisation idDataSpec={this.state.idDataSpec}/>                                    
                                </div>


                                <div label='Log'>
                                    {this.log()}
                                </div>

                                <div label={`Versions(${this.state.countVersions})`}  forceRender={true}>
                                    {this.versions()}
                                </div>


                                <div label='Developer'>
                                    <table>
                                        {this.viz &&
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>dot</th>
                                                <td><pre>{this.viz.dot}</pre></td>
                                            </tr>
                                        }

                                        {this.state.jsonSchema &&
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>json Schema</th>
                                                <td><pre>
                                                    {this.state.jsonSchema}{"\n\n"}    
                                                </pre></td>
                                            </tr>
                                        }


                                        {this.state.xsd &&
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>xml Schema</th>
                                                <td><pre>
                                                    {this.state.xsd}{"\n\n"}    
                                                </pre></td>
                                            </tr>
                                        }


                                    </table>

                                </div>


                                <div label='Metadata'>
                                    <ViewMetadata Data={this.state.DataSpec} />
                                </div>



                                <div label="used in ...">
                                </div>


                            </PD.Tabs>


                        </div>
                    )
            };
        };

        return <div />;
    };


    FormRootElement(){

        if (!this.state.FormData.Serialisation){
            this.state.FormData.Serialisation = {Root:{}};
        }

        if (!this.state.FormData.Serialisation.Root){
            this.state.FormData.Serialisation.Root = {};
        }

        let Classes = null;
        if (this.state.DataSpec){
            if (this.state.DataSpec.Classes){
                Classes = this.state.DataSpec.Classes;
            }
        }

        return(
            <div>
                <FormElement
                    key="0"
                    mode="edit"
//                    onAdd={this.addElement}
//                    onRemove={this.removeElement}
                    onUpdate={this.updateElement}
                    Classes={Classes}
                    Element={this.state.FormData.Serialisation.Root}
                    seq={0}/>
            </div>
        )
 
    };

    updateElement(seq, Element){

        let FormData = this.state.FormData;

        FormData.Serialisation.Root = Element;
        this.setState({"FormData": FormData});

    }


};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <DataSpec {...props} navigation={navigation} location={location} params={params} />;
  }
