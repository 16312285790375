import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {HtmlToText, getParam} from '../utils/utils';
import {formatText, truncate} from '../utils/utils';
import {cloneObject} from '../utils/utils';

import Buffering from "../components/Buffering"; 

import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewClass from "../components/ViewClass";
import ViewDataSpec from "../components/ViewDataSpec";
import ViewMetadata from "../components/ViewMetadata";

import SelectEntity from "../components/SelectEntity";
import SelectRelationship from "../components/SelectRelationship";

import FormProperty from "../components/FormProperty";
import ListProperties from "../components/ListProperties";


import Revision from "../components/Revision";
import ListRevisions from "../components/ListRevisions";


import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

class Class extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            renderState: null
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "dot";


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVizClick = this.handleVizClick.bind(this);

        this.addProperty = this.addProperty.bind(this);
        this.removeProperty = this.removeProperty.bind(this);
        this.updateProperty = this.updateProperty.bind(this);
        this.movePropertyUp = this.movePropertyUp.bind(this);
        this.movePropertyDown = this.movePropertyDown.bind(this);

        this.refRevision = React.createRef();
        this.loadedRevisions = this.loadedRevisions.bind(this);

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/`;

    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.vizNodeId){
                let partsVizNodeId = this.state.vizNodeId.split('_');
                switch(partsVizNodeId[0]){
                    case 'structure':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/structure/${partsVizNodeId[1]}`);
                        }
                        break;
        
                }

            }


            if (this.state.loadDataSpec){
                this.loadDataSpec();
            }

            if (this.state.loadClass){
                this.loadClass();
            }

            if (this.state.renderState == "loading"){
                if (this.state.isFailed){
                    StateChange.renderState = "failed";
                }
                else{
                    switch (this.state.mode){
                        case "new":
                            if (this.state.isLoadedDataSpec){
                                StateChange.renderState = "loaded";
                            }
                        default:
                            if (this.state.isLoadedClass){
                                StateChange.DataSpec = this.state.Class.DataSpec;
                                StateChange.idDataSpec = this.state.Class.DataSpec._id;
                                this.FallBackNavigation = `/dataspec/${StateChange.idDataSpec}`;
                                StateChange.renderState = "loaded";
                            }
                            break;                        
                    }
                }
            }

            if (this.state.renderState == "updating"){
                if (this.state.isError){
                    StateChange.renderState = "error";
                    StateChange.isError = false;
                }
                else{
                    if (this.state.isUpdated){
                        StateChange.isUpdated = false;
                        StateChange.renderState = "updated";
                    }
                }
            }

            if (this.state.renderState == "updated"){
                this.props.navigation(`/class/${this.state.idClass}`);
            }

            if (this.state.isDeleted){
                this.props.navigation(this.FallBackNavigation);
            }


            if (this.state.renderState == "cancelled"){
                switch (this.state.mode){
                    case "edit":
                    case "delete":
                        this.props.navigation(`/class/${this.state.idClass}`);
                        break;
                    case "new":
                        this.props.navigation(this.FallBackNavigation);
                        break;
                }
            }

            if (this.state.renderState == "loaded"){

                StateChange.OfferEdit = false;
                if (this.state.DataSpec){
                    if (this.dsd.loggedon){
                        if (this.dsd.user.id == this.state.DataSpec.idUser){
                            StateChange.OfferEdit = true;
                        }
                    }
                }

                if (!StateChange.OfferEdit){
                    switch (this.state.mode){
                        case "new":
                        case "edit":
                        case "delete":
                            if (!this.state.OfferEdit){
                                StateChange.renderState = "denied"
                                StateChange.errorMessage = "You cannot add or edit Classes to this Data Spec"
                            }
                            break;
                    }
                }

            }
            this.setState(StateChange);

        }


    }
    
    loadDataSpec(){

        let StateChange = {};
        StateChange.loadDataSpec = false;
        StateChange.isLoadedDataSpec = false;
        StateChange.DataSpec = null;

        if (this.state.idDataSpec){
            StateChange.renderState = "loading";
            this.dsd.getDataSpec(this.state.idDataSpec,"DataSpec","isLoadedDataSpec");
        }

        this.setState(StateChange);

        return;
        
    };

    loadClass(){

        let StateChange = {};
        StateChange.loadClass = false;
        StateChange.isLoadedClass = false;
        StateChange.Class = null;


        if (this.state.idClass){
            StateChange.renderState = "loading";
            this.dsd.getClass(this.state.idClass,"Class","isLoadedClass");
        }

        this.setState(StateChange);

        return;
        
    };



    setup(){

        let StateChange = {

            mode: "view",

            idClass: null,
            idDataSpec: null,

            DataSpec: null,
            loadDataSpec: false,
            isLoadedDataSpec: false,

            Class: null,
            loadClass: false,
            isLoadedClass: false,

            isUpdated: false,
            isDeleted: false,

            isFailed: false,
            isError: false,
            errorMessage: null,

            renderState: null,


            FormData: {},
            OfferEdit:false,

            Controls: {
                vizFullSize :true
            },

            countRevisions: 0,

            vizNodeId: null

        }

        if (getParam('mode', this.props)){
            StateChange.mode = getParam('mode', this.props);
        }
        StateChange.idClass = getParam('id', this.props);
        StateChange.idDataSpec = getParam('idDataSpec', this.props);

        switch (StateChange.mode){
            case 'new':
                if (!StateChange.idDataSpec){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Data Spec not specified when adding a Class"
                }
                break;
            default:
                if (StateChange.idDataSpec){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Data Spec should not be specified for an existing Class"
                }
                if (!StateChange.idClass){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Class not specified for an existing Class"
                }
                break;
        }

        if (StateChange.mode == "new"){
            if (StateChange.idDataSpec){
                this.FallBackNavigation = `/dataspec/${StateChange.idDataSpec}`;
            }        
        }

        if (StateChange.idDataSpec){
            StateChange.loadDataSpec = true;
        }

        if (StateChange.idClass){
            StateChange.loadClass = true;
        }


        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        switch (FormFieldName){
            case 'vizFullSize':
                let Controls = this.state.Controls;
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        
                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    
    handleSubmit(event) {

        switch (event.target.name){
            
            case 'btnCancel':

                this.setState({
                    renderState: "cancelled"
                })

                break;
            case 'btnDelete':
                this.dsd.deleteClass(this.state.idDataSpec,this.state.idClass);
                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false,
                    renderState: "updating"
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addClassToDataSpec(this.state.idDataSpec,this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editClass(this.state.idClass,this.state.FormData);
                        this.refRevision.current.update();
                        break;
                    default:
                        break; 
                };
    
        };

        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
        }

        this.setState(StateChange);

        event.preventDefault();
    };



    addProperty(){
        let FormData = this.state.FormData;

        if (!('Properties' in FormData)){
            FormData['Properties'] = [];
        }
        if (!FormData.Properties){
            FormData['Properties'] = [];
        }

        FormData.Properties.push({});
        this.setState({"FormData": FormData});
    }

    removeProperty(seq){

        let FormData = this.state.FormData;

        if (!('Properties' in FormData)){
            FormData['Properties'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Properties.length;pos++){
            let item = FormData.Properties[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Properties = arrNew;
        this.setState({"FormData": FormData});

    }


    movePropertyUp(seq){

        let FormData = this.state.FormData;

        if (!('Properties' in FormData)){
            FormData['Properties'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Properties.length;pos++){
            let item = FormData.Properties[pos];
            if (pos == (seq-2)){
                item = FormData.Properties[pos+1];
            }
            if (pos == (seq-1)){
                item = FormData.Properties[pos-1];
            }
            arrNew.push(item); 
        }

        FormData.Properties = arrNew;
        this.setState({"FormData": FormData});

    }

    movePropertyDown(seq){

        let FormData = this.state.FormData;

        if (!('Properties' in FormData)){
            FormData['Properties'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Properties.length;pos++){
            let item = FormData.Properties[pos];
            if (pos == (seq-1)){
                item = FormData.Properties[pos+1];
            }
            if (pos == (seq)){
                item = FormData.Properties[pos-1];
            }
            arrNew.push(item); 
        }

        FormData.Properties = arrNew;
        this.setState({"FormData": FormData});

    }


    updateProperty(seq, Property){


        if ('description' in Property){
            Property.description = sanitizeHtml(Property.description);
        }


        let FormData = this.state.FormData;

        if (!('Properties' in FormData)){
            FormData['Properties'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Properties.length;pos++){
            let item = FormData.Properties[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Property;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Property);
        }

        FormData.Properties = arrNew;
        this.setState({"FormData": FormData});

    }


    handleVizClick(event) {

        let StateChange = {};

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }
        

        StateChange.vizNodeId = idNode;

        this.setState(StateChange);

        event.preventDefault();

    };


    loadedRevisions(count) {

        let StateChange = {};

        StateChange.countRevisions = 0;
        if (count){
            StateChange.countRevisions = count;
        }

        this.setState(StateChange);


    };


    visualise(){
    
        if (!this.state.Class){
            return null;
        }


        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };


        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.fit = false;
        vizoptions.width = null;
        vizoptions.height = null;
        vizoptions.zoom = false;
        vizoptions.scale = 1;

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            return(
                <div>
                    <table>
                        <tr>
                            <td>
                            <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                            </td>
                        </tr>
                    </table>

                    <div name="divViz" style={styleDiv} onClick={this.handleVizClick}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };


    render() {

        switch (this.state.renderState){
            case "invalid":
            case "failed":
            case "denied":
                let Message = "An error has occured";
                if (this.state.errorMessage){
                    Message = this.state.errorMessage;
                }
                return <PD.ErrorBox>{Message}</PD.ErrorBox>
                break;
            case "loading":
                return <Buffering/>
                break;
            case "cancelled":
                return <p>Cancelled</p>
                break;
    
        }


        switch (this.state.mode){
            case 'new':
            case 'edit':

                if (this.state.Class){
                    this.state.FormData = this.state.Class;
                }

                switch (this.state.renderState){
                    case "loaded":
                    case "updating":
                    case "error":
                        return (
                            <div>

                                <PD.Heading>{this.state.mode} Class</PD.Heading>

                                {this.state.renderState == "error" &&
                                    <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                                }

                                <form> 
                                    <PD.Input label="Name" width="60" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                                    <PD.Radio label="Source Type" name="sourceType" value={this.state.FormData.sourceType} onChange={this.handleChange}>
                                        {
                                            this.dsd.config.ClassSourceTypes.map(
                                                (type, posType) => 
                                                    {
                                                        return(
                                                            <label>{type}</label>
                                                        )
                                                    }
                                            )
                                        }
                                    </PD.Radio>

                                    {this.state.FormData.sourceType=='entity' &&
                                        <fieldset>
                                            <legend>Entity</legend>                              
                                            <SelectEntity name="idEntity" label="Entity" selectLogicalModel={true} onChange={this.handleChange} value={this.state.FormData.idEntity}/>
                                        </fieldset>
                                    }

                                    {this.state.FormData.sourceType=='relationship' &&
                                        <fieldset>
                                            <legend>Relationship</legend>                              
                                            <SelectRelationship name="idRelationship" label="Relationship" selectLogicalModel={true} onChange={this.handleChange} value={this.state.FormData.idRelationship}/>
                                        </fieldset>
                                    }


                                    <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>
                                    <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>


                                    {this.FormProperties()}

                                    <Revision ref={this.refRevision}  idAbout={this.state.idClass}/>

                                    
                                    {(() => {
                                        switch (this.state.renderState) {
                                            case "updating":
                                                return (
                                                    <div>
                                                        <PD.Button disabled={true} type="submit" name="btnSave">
                                                            <Buffering/>
                                                        </PD.Button>
                                                    </div>
                                                )
                                            default:
                                                return (
                                                    <div>
                                                        <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave" value="Save" />    
                                                        <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" /> 
                                                    </div>
                                                )
                                        }
                                    })()}

                                </form>

                            </div>    
                        );
                }
                break;
            case 'delete':
                return (
                    <div>
                        <PD.Heading>{this.state.mode} Class</PD.Heading>

                        {this.state.isFailed &&
                            <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                        }

                        <PD.Button name="btnDelete" onClick={this.handleSubmit} type="warning" value="confirm delete?" />
                        <PD.Button name="btnCancel" onClick={this.handleSubmit} type="warning" value="cancel" />
                        <ViewClass Class={this.state.Class}/>
                    </div>
                )
            case 'view':
            default:
                
                switch (this.state.renderState){
                    case "loaded":

                        this.viz = this.dsd.vizClass({Class:this.state.Class}, this.state.Controls, this.vizEngine );

                        let countProperties = 0;
                        if (this.state.Class.Properties){
                            countProperties = this.state.Class.Properties.length;
                        }

                        return (
                            <div>
                                <div>
                                    <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                    <PD.Heading>Class:{this.state.Class.name}</PD.Heading>
                                </div>

                                {this.state.OfferEdit &&
                                    <span style={{paddingRight:10}}>
                                            <PD.Button onClick={this.handleMode} type="submit" value="edit" />                        
                                            <PD.Button onClick={this.handleMode} type="submit" value="delete" />
                                    </span>
                                }

                                <ViewClass Class={this.state.Class}/>

                                <br/>

                                <PD.Tabs>

                                    <div label='Visualise'>
                                        <table>
                                            <tr>
                                                <td style={{verticalAlign: 'top', width: 'auto'}}>
                                                    {this.visualise()}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div label={`Properties(${countProperties})`}>
                                        <ListProperties Properties={this.state.Class.Properties}/>
                                    </div>

                                    <div label='Data Spec'>
                                        <ViewDataSpec DataSpec={this.state.DataSpec} />
                                    </div>

                                    <div label='Metadata'>
                                        <ViewMetadata Data={this.state.Class} />
                                    </div>

                                    <div label={`Revisions(${this.state.countRevisions})`} forceRender={true}>
                                        <ListRevisions OfferEdit={this.state.OfferEdit} filters={{idAbout:this.state.idClass}} onLoad={this.loadedRevisions}/>
                                    </div>

                                    <div label='Developer'>
                                        <table>
                                            {this.viz &&
                                                <tr>
                                                    <th style={{verticalAlign:"top"}}>dot</th>
                                                    <td><pre>{this.viz.dot}</pre></td>
                                                </tr>
                                            }
                                        </table>
                                    </div>

                                </PD.Tabs>


                            </div>
                        )
                }
        };

        return <div />;
    };


    FormProperties(){

        let seqNext = 1;
        if (this.state.FormData.Properties){
            seqNext = this.state.FormData.Properties.length + 1;
        }
        return (
            <fieldset>
                <legend>Properties</legend>
                {this.state.FormData.Properties &&
                    this.state.FormData.Properties.map(
                        (Property, posProperty) => 
                        {
                            let seq = posProperty+1;
                            return(
                                this.FormProperty('edit', seq, Property)
                            )
                        }
                    )
                }
                {this.FormProperty('new', seqNext)}
            </fieldset>
        );

    }

    FormProperty(mode, seq, Property = {}){
        let idEntity = null;
        let idRelationship = null;

        switch (this.state.FormData.sourceType){
            case 'entity':
                idEntity = this.state.FormData.idEntity;
                break;
            case 'relationship':
                idRelationship = this.state.FormData.idRelationship;
                break;    
        }

        let onMoveUp = null;
        if (seq>1) {
            onMoveUp = this.movePropertyUp;
        }

        let onMoveDown = null;
        if (this.state.FormData.Properties){
            if (seq<this.state.FormData.Properties.length) {
                onMoveDown = this.movePropertyDown;
            }
        }


        return(
            <div>
                <FormProperty 
                    key={seq.toString()}
                    parentSourceType={this.state.FormData.sourceType}
                    idEntity={idEntity}
                    idRelationship={idRelationship}
                    mode={mode}
                    onAdd={this.addProperty}
                    onRemove={this.removeProperty}
                    onUpdate={this.updateProperty}
                    {...(onMoveUp && { onMoveUp:onMoveUp})}
                    {...(onMoveDown && { onMoveDown:onMoveDown})}
                    Property={Property}
                    seq={seq}/>
            </div>
        )
    }

};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Class {...props} navigation={navigation} location={location} params={params} />;
  }
