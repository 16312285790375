import React from "react"

import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';


import { isNull, isEqual, isArray } from "lodash";

import {hasProperty, formatText, formatDate} from '../utils/utils';
import {cloneObject, getParam, truncate} from '../utils/utils';
import {SanitizeHTML,  HtmlToText} from '../utils/utils';
import { Graphviz } from "graphviz-react";

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';

import ViewConceptModel from "../components/ViewConceptModel";
import ViewLogicalModel from "../components/ViewLogicalModel";
import ViewDataDictionary from "../components/ViewDataDictionary";
import ViewDataSpec from "../components/ViewDataSpec";
import ViewProcess from "../components/ViewProcess";
import ViewStep from "../components/ViewStep";


import ViewConcept from "../components/ViewConcept";
import ViewLink from "../components/ViewLink";

import ViewEntity from "../components/ViewEntity";
import ViewRelationship from "../components/ViewRelationship";

import ViewStructure from "../components/ViewStructure";

import ViewClass from "../components/ViewClass";


class ViewVersion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };


        this.handleControls = this.handleControls.bind(this);
        this.handleNavClick = this.handleNavClick.bind(this);
        this.handleExport = this.handleExport.bind(this);

        this.dsd = new dsd();
        this.dsd.StateObject = this;

    }

    componentDidMount(){

        this.setup();
 
     }

     componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props.params, prevProps.params)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.loadData){
                this.loadData(this.state.idVersion);
                StateChange.loadData = false;
            }

            if (this.state.isLoaded){
                if (this.state.Version.Document){
                    StateChange.Document = this.state.Version.Document;
                }
            }

            this.setState(StateChange);
        }

     }



    setup(){

        let StateChange = {
            idVersion: null,
            type: null,
            Version: null,
            Document: null,
            isLoaded: false,
            loadData: false,

            Controls: {
            },


        }

        StateChange.idVersion = getParam('idVersion', this.props);
        if (StateChange.idVersion){
            StateChange.loadData = true;
        }

        StateChange.Document = getParam('Document', this.props);
        if (StateChange.Document){
            StateChange.loadData = false;
            StateChange.isLoaded = true;
        }


        StateChange.Version = getParam('Version', this.props);
        if (StateChange.Version){
            StateChange.Document = StateChange.Version.Document;
            StateChange.loadData = false;
            StateChange.isLoaded = true;
        }

        StateChange.type = getParam('type', this.props);

        this.setState(StateChange);

    }

    loadData(id){

        if (id){
            this.dsd.getVersion(id);
        }

        return;
    }

    handleControls(event){

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let Controls = this.state.Controls;
        Controls[FormFieldName] = FormFieldValue;        
        this.setState({"Controls": Controls});

        return;

    }


    handleNavClick(event) {

        let idNode = null;

        if ("hash" in event.target){
            idNode = event.target.hash.substring(1);
        }
        else if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }

        if (idNode){
            const id = `${idNode}`
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView({
                block: "start",
                behavior: "smooth",
              });
//              window.location.hash = `#${id}`;
            }

        }

        event.preventDefault();

    };


    async handleExport(event){

        let Version = cloneObject(this.state.Version);
        let id = null;
        if (Version.Document){
            id = Version.Document._id;
        }
        let zipData = null;
        let link = null;
        switch (this.state.type) {
            case 'dataspec':

                zipData = await this.dsd.zipDataSpec(Version);

                link = document.createElement("a");
                link.href = window.URL.createObjectURL(zipData);
                link.download = `dataspec_${id}_version_${Version._id}.zip`;
                link.click();
            
                break;

            case 'process':

                zipData = await this.dsd.zipProcess(Version);

                link = document.createElement("a");
                link.href = window.URL.createObjectURL(zipData);
                link.download = `process_${id}_version_${Version._id}.zip`;
                link.click();
            
                break;


        }

    }

    viewVersion(Version){

        if (Version){
            return(
                <fieldset>
                    <legend>Named Version</legend>
                    <PD.Table className="panel">
                        <PD.Tbody>                                

                            {hasProperty(Version,'name') &&
                                <PD.Tr>                                
                                    <PD.Th>Name</PD.Th><PD.Td>{Version.name}</PD.Td>
                                </PD.Tr>
                            }

                            {hasProperty(Version,'description') &&
                                <PD.Tr>
                                    <PD.Th>Description</PD.Th><PD.Td><SanitizeHTML html={Version.description}/></PD.Td>
                                </PD.Tr>
                            }

                            {hasProperty(Version,'createdAt') &&
                                <PD.Tr>
                                    <PD.Th>Date/Time</PD.Th><PD.Td>{formatDate(Version.createdAt,'dmy hms')}</PD.Td>
                                </PD.Tr>
                            }

                        </PD.Tbody>
                    </PD.Table>
                </fieldset>
            )
        };
        return null;
    };


    viewProcess(Process){
        if (Process){

            let countSteps = 0;
            if (Process.Steps){
                if (Process.Steps.length){
                    countSteps = Process.Steps.length;
                }
            }

            let vizoptions = {}
            vizoptions.engine = 'neato';
            vizoptions.fit = false;
            vizoptions.width = null;
            vizoptions.height = null;
            vizoptions.zoom = false;
            vizoptions.scale = 1;

            let styleDiv = {}

            const viz = this.dsd.vizProcess(Process, vizoptions.engine, this.state.Controls.vizSwimlanes, false);

            return(
                <div>
                    <ViewProcess Process={Process} noLinks/>

                    <fieldset>
                        <legend>visualisation</legend>
                        <div>
                            <label  className="field-legend" htmlFor="vizSwimlanes">
                                Swimlanes?
                            </label>
                            <input id="vizSwimlanes" name="vizSwimlanes" type="checkbox" onChange={this.handleControls} checked={this.state.Controls.vizSwimlanes}/>
                        </div>
                        <div name="divVizProcess" style={styleDiv}>
                            <Graphviz dot={viz.dot} options={vizoptions}/>
                        </div>
                    </fieldset>

                    <PD.Heading level="2">Index</PD.Heading>

                    {countSteps &&
                        <fieldset>
                            <legend>Steps</legend>
                            <PD.Table className="list">
                                <PD.Thead>
                                    <PD.Tr>
                                        <PD.Th>Name</PD.Th>
                                        <PD.Th>Description</PD.Th>
                                    </PD.Tr>
                                </PD.Thead>
                                <PD.Tbody>
                                    {
                                        Process.Steps.map(
                                            (Step, pos) => 
                                            {                
                                                switch (Step.status){
                                                    case 'deprecated': return null;
                                                    default:

                                                        return (

                                                            <PD.Tr key={pos}>
                                                                <PD.Td>
                                                                    <PD.PdNavLink to={`#step_${Step._id}`} onClick={this.handleNavClick}>{Step.name}</PD.PdNavLink>
                                                                </PD.Td>
                                                                <PD.Td>
                                                                    {formatText(truncate(HtmlToText(Step.description), 400))}
                                                                </PD.Td>
                                                            </PD.Tr>
                                                        )
                                                }
                                            }
                                    
                                        )
                                    }
                                </PD.Tbody>
                            </PD.Table>
                        </fieldset>
                    }


                    {
                        countSteps &&
                            <div>
                                <PD.Heading level="2">Steps</PD.Heading>
                                {
                                    Process.Steps.map(
                                        (Step, pos) =>    
                                        {
                                            
                                            switch (Step.status){
                                                case 'deprecated': return null;
                                                default:
                                                    return (
                                                        <fieldset id={`step_${Step._id}`}>
                                                            <ViewStep key={pos} Step={Step} noLinks/>
                                                        </fieldset>
                                                    )
                                            }
                                        }
                                    )
                                }
                            </div>
                    }

                </div>
            )
        }
    }



    viewConceptModel(ConceptModel){
        if (ConceptModel){

            let countConcepts = 0;
            if (ConceptModel.Concepts){
                if (ConceptModel.Concepts.length){
                    countConcepts = ConceptModel.Concepts.length;
                }
            }

            let countLinks = 0;
            if (ConceptModel.Links){
                if (ConceptModel.Links.length){
                    countLinks = ConceptModel.Links.length;
                }
            }

            const viz = this.dsd.vizConceptModel({ConceptModel:ConceptModel}, {}, 'dot' );

            let vizoptions = {}
            vizoptions.engine = 'dot';
            vizoptions.fit = false;
            vizoptions.width = null;
            vizoptions.height = null;
            vizoptions.zoom = false;
            vizoptions.scale = 1;

            let styleDiv = {}


            return(
                <div>
                    <PD.Heading>{`Concept Model: ${ConceptModel.name}`}</PD.Heading>

                    <ViewConceptModel ConceptModel={ConceptModel} noLinks/>

                    <fieldset>
                        <legend>visualisation</legend>
                        <div name="divVizConceptModel" style={styleDiv} onClick={this.handleNavClick}>
                            <Graphviz dot={viz.dot} options={vizoptions}/>
                        </div>
                    </fieldset>

                    <PD.Heading level="2">Index</PD.Heading>
                    {countConcepts &&
                        <fieldset>
                            <legend>Concepts</legend>
                            <PD.Table className="list">
                                <PD.Thead>
                                    <PD.Tr>
                                        <PD.Th>Name</PD.Th>
                                        <PD.Th>Description</PD.Th>
                                    </PD.Tr>
                                </PD.Thead>
                                <PD.Tbody>
                                    {
                                        ConceptModel.Concepts.map(
                                            (Concept, pos) => 
                                            {                
                                                switch (Concept.status){
                                                    case 'deprecated': return null;
                                                    default:
                                                        return (

                                                            <PD.Tr key={pos}>
                                                                <PD.Td>
                                                                    <PD.PdNavLink to={`#concept_${Concept._id}`} onClick={this.handleNavClick}>{Concept.name}</PD.PdNavLink>
                                                                </PD.Td>
                                                                <PD.Td>
                                                                    {formatText(truncate(HtmlToText(Concept.description), 400))}
                                                                </PD.Td>
                                                            </PD.Tr>

                                                        )
                                                }
                                            }
                                    
                                        )
                                    }
                                </PD.Tbody>
                            </PD.Table>
                        </fieldset>
                    }



                    {countLinks &&
                        <fieldset>
                            <legend>Links</legend>
                            <PD.Table className="list">
                                <PD.Thead>
                                    <PD.Tr>
                                        <PD.Th>Concept From</PD.Th>                            
                                        <PD.Th>Link</PD.Th>
                                        <PD.Th>Concept To</PD.Th>                            
                                        <PD.Th>Description</PD.Th>
                                    </PD.Tr>
                                </PD.Thead>
                                <PD.Tbody>
                                    {
                                        ConceptModel.Links.map(
                                            (Link, pos) => 
                                            {                
                                                switch (Link.status){
                                                    case 'deprecated': return null;
                                                    default:
                                                        return (

                                                            <PD.Tr key={pos}>
                                                                <PD.Td>{Link.ConceptFrom.name}</PD.Td>
                                                                <PD.Td><PD.PdNavLink to={`#link_${Link._id}`} onClick={this.handleNavClick}>{Link.name}</PD.PdNavLink></PD.Td>
                                                                <PD.Td>{Link.ConceptTo.name}</PD.Td>
                                                                <PD.Td>
                                                                    {formatText(truncate(HtmlToText(Link.description), 400))}
                                                                </PD.Td>

                                                            </PD.Tr>

                                                        )
                                                }
                                            }
                                    
                                        )
                                    }
                                </PD.Tbody>
                            </PD.Table>
                        </fieldset>
                    }



                    {
                        countConcepts &&
                            <div>
                                <PD.Heading level="2">Concepts</PD.Heading>
                                {
                                    ConceptModel.Concepts.map(
                                        (Concept, pos) => 
                                        {
                                            switch (Concept.status){
                                                case 'deprecated': return null;
                                                default:

                                                    let ConceptLinks = [];
                                                    for (let posL=0; posL < ConceptModel.Links.length; posL++){
                                                        let Link = cloneObject(ConceptModel.Links[posL]);
                                                        if (Link.idConceptFrom == Concept._id || Link.idConceptTo == Concept._id){
                                                            ConceptLinks.push({Concept:Concept, Link:Link});
                                                        }
                                                    }
                                        
                                                    const viz = this.dsd.vizConcept({Concept:Concept,ConceptModel:ConceptModel, ConceptLinks:ConceptLinks}, {}, 'dot' );
                                                    return (
                                                        <fieldset id={`concept_${Concept._id}`}>
                                                            <ViewConcept key={pos} Concept={Concept} noLinks/>

                                                            <fieldset>
                                                                <legend>visualisation</legend>
                                                                <div name="divVizConcept" style={styleDiv} onClick={this.handleNavClick}>
                                                                    <Graphviz dot={viz.dot} options={vizoptions}/>
                                                                </div>
                                                            </fieldset>
                                                        </fieldset>
                                                    )
                                            }
                                        }
                                    )
                                }
                            </div>
                    }



                    {
                        countLinks &&
                            <div>
                                <PD.Heading level="2">Links</PD.Heading>
                                {
                                    ConceptModel.Links.map(
                                        (Link, pos) => 
                                        {                
                                            switch (Link.status){
                                                case 'deprecated': return null;
                                                default:

                                                    const viz = this.dsd.vizLink({Link:Link}, {}, 'dot' );
                                                    return (
                                                        <fieldset id={`link_${Link._id}`}>
                                                            <ViewLink key={pos} Link={Link} noLinks/>

                                                            <fieldset>
                                                                <legend>visualisation</legend>
                                                                <div name="divVizLink" style={styleDiv} onClick={this.handleNavClick}>
                                                                    <Graphviz dot={viz.dot} options={vizoptions}/>
                                                                </div>
                                                            </fieldset>
                                                        </fieldset>
                                                    )
                                            }
                                        }
                                    )
                                }
                            </div>
                    }


                </div>
            )
        }
    }


    viewLogicalModel(LogicalModel){
        if (LogicalModel){

            let countEntities = 0;
            if (LogicalModel.Entities){
                if (LogicalModel.Entities.length){
                    countEntities = LogicalModel.Entities.length;
                }
            }

            let countRelationships = 0;
            if (LogicalModel.Relationships){
                if (LogicalModel.Relationships.length){
                    countRelationships = LogicalModel.Relationships.length;
                }
            }

            const viz = this.dsd.vizLogicalModel({LogicalModel:LogicalModel}, {}, 'dot' );

            let vizoptions = {}
            vizoptions.engine = 'dot';
            vizoptions.fit = false;
            vizoptions.width = null;
            vizoptions.height = null;
            vizoptions.zoom = false;
            vizoptions.scale = 1;

            let styleDiv = {}


            return(
                <div>
                    <ViewLogicalModel LogicalModel={LogicalModel} noLinks/>

                    <fieldset>
                        <legend>visualisation</legend>
                        <div name="divVizLogicalModel" style={styleDiv} onClick={this.handleNavClick}>
                            <Graphviz dot={viz.dot} options={vizoptions}/>
                        </div>
                    </fieldset>

                    <PD.Heading level="2">Index</PD.Heading>
                    {countEntities &&
                        <fieldset>
                            <legend>Entities</legend>
                            <PD.Table className="list">
                                <PD.Thead>
                                    <PD.Tr>
                                        <PD.Th>Name</PD.Th>
                                        <PD.Th>Description</PD.Th>
                                    </PD.Tr>
                                </PD.Thead>
                                <PD.Tbody>
                                    {
                                        LogicalModel.Entities.map(
                                            (Entity, pos) => 
                                            {
                                                switch (Entity.status){
                                                    case 'deprecated': return null;
                                                    default:
                                                        return (

                                                            <PD.Tr key={pos}>
                                                                <PD.Td>
                                                                    <PD.PdNavLink to={`#entity_${Entity._id}`} onClick={this.handleNavClick}>{Entity.name}</PD.PdNavLink>
                                                                </PD.Td>
                                                                <PD.Td>
                                                                    {formatText(truncate(HtmlToText(Entity.description), 400))}
                                                                </PD.Td>
                                                            </PD.Tr>

                                                        )
                                                }
                                            }
                                    
                                        )
                                    }
                                </PD.Tbody>
                            </PD.Table>
                        </fieldset>
                    }



                    {countRelationships &&
                        <fieldset>
                            <legend>Relationships</legend>
                            <PD.Table className="list">
                                <PD.Thead>
                                    <PD.Tr>
                                        <PD.Th>Name</PD.Th>                            
                                        <PD.Th>Description</PD.Th>
                                    </PD.Tr>
                                </PD.Thead>
                                <PD.Tbody>
                                    {
                                        LogicalModel.Relationships.map(
                                            (Relationship, pos) => 
                                            {                                                
                                                switch (Relationship.status){
                                                    case 'deprecated': return null;
                                                    default:
                                                        return (

                                                            <PD.Tr key={pos}>
                                                                <PD.Td>
                                                                    <PD.PdNavLink to={`#relationship_${Relationship._id}`} onClick={this.handleNavClick}>{Relationship.label}</PD.PdNavLink>
                                                                </PD.Td>
                                                                <PD.Td>
                                                                    {formatText(truncate(HtmlToText(Relationship.description), 400))}
                                                                </PD.Td>

                                                            </PD.Tr>

                                                        )
                                                }
                                            }
                                    
                                        )
                                    }
                                </PD.Tbody>
                            </PD.Table>
                        </fieldset>
                    }


                    {
                        countEntities &&
                            <div>
                                <PD.Heading level="2">Entities</PD.Heading>
                                {
                                    LogicalModel.Entities.map(
                                        (Entity, pos) => 
                                        {
                                            switch (Entity.status){
                                                case 'deprecated': return null;
                                                default:
                                                    const viz = this.dsd.vizEntity({Entity:Entity}, {}, 'dot' );
                                                    return (
                                                        <fieldset id={`entity_${Entity._id}`}>
                                                            <ViewEntity key={pos} Entity={Entity} noLinks/>

                                                            <fieldset>
                                                                <legend>visualisation</legend>
                                                                <div name="divVizEntity" style={styleDiv} onClick={this.handleNavClick}>
                                                                    <Graphviz dot={viz.dot} options={vizoptions}/>
                                                                </div>
                                                            </fieldset>
                                                        </fieldset>
                                                    )
                                            }
                                        }
                                    )
                                }
                            </div>
                    }


                    {
                        countRelationships &&
                            <div>
                                <PD.Heading level="2">Relationships</PD.Heading>
                                {
                                    LogicalModel.Relationships.map(
                                        (Relationship, pos) => 
                                        {        
                                            switch (Relationship.status){
                                                case 'deprecated': return null;
                                                default:
                                                    return (
                                                        <fieldset id={`relationship_${Relationship._id}`}>
                                                            <ViewRelationship key={pos} Relationship={Relationship} noLinks/>
                                                        </fieldset>
                                                    )
                                            }
                                        }
                                    )
                                }
                            </div>
                    }

                </div>
            )
        }
    }


    viewDataDictionary(DataDictionary){
        if (DataDictionary){

            let countStructures = 0;
            if (DataDictionary.Structures){
                if (DataDictionary.Structures.length){
                    countStructures = DataDictionary.Structures.length;
                }
            }

            let vizoptions = {}
            vizoptions.engine = 'dot';
            vizoptions.fit = false;
            vizoptions.width = null;
            vizoptions.height = null;
            vizoptions.zoom = false;
            vizoptions.scale = 1;

            let styleDiv = {}

            return(
                <div>
                    <ViewDataDictionary DataDictionary={DataDictionary} noLinks/>

                    <PD.Heading level="2">Index</PD.Heading>
                    {countStructures &&
                        <fieldset>
                            <legend>Structues</legend>
                            <PD.Table className="list">
                                <PD.Thead>
                                    <PD.Tr>
                                        <PD.Th>Name</PD.Th>
                                        <PD.Th>Description</PD.Th>
                                    </PD.Tr>
                                </PD.Thead>
                                <PD.Tbody>
                                    {
                                        DataDictionary.Structures.map(
                                            (Structure, pos) => 
                                            {                
                                                switch (Structure.status){
                                                    case 'deprecated': return null;
                                                    default:

                                                        return (

                                                            <PD.Tr key={pos}>
                                                                <PD.Td>
                                                                    <PD.PdNavLink to={`#structure_${Structure._id}`} onClick={this.handleNavClick}>{Structure.name}</PD.PdNavLink>
                                                                </PD.Td>
                                                                <PD.Td>
                                                                    {formatText(truncate(HtmlToText(Structure.description), 400))}
                                                                </PD.Td>
                                                            </PD.Tr>
                                                        )
                                                }
                                            }
                                    
                                        )
                                    }
                                </PD.Tbody>
                            </PD.Table>
                        </fieldset>
                    }


                    {
                        countStructures &&
                            <div>
                                <PD.Heading level="2">Structures</PD.Heading>
                                {
                                    DataDictionary.Structures.map(
                                        (Structure, pos) =>    
                                        {
                                            
                                            switch (Structure.status){
                                                case 'deprecated': return null;
                                                default:

                                                    const viz = this.dsd.vizStructure({Structure:Structure}, {}, 'dot' );
                                                    return (
                                                        <fieldset id={`structure_${Structure._id}`}>
                                                            <ViewStructure key={pos} Structure={Structure} noLinks/>

                                                            <fieldset>
                                                                <legend>visualisation</legend>
                                                                <div name="divVizStructure" style={styleDiv} onClick={this.handleNavClick}>
                                                                    <Graphviz dot={viz.dot} options={vizoptions}/>
                                                                </div>
                                                            </fieldset>
                                                        </fieldset>
                                                    )
                                            }
                                        }
                                    )
                                }
                            </div>
                    }

                </div>
            )
        }
    }

    viewDataSpec(DataSpec){
        if (DataSpec){

            let countClasses = 0;
            if (DataSpec.Classes){
                if (DataSpec.Classes.length){
                    countClasses = DataSpec.Classes.length;
                }
            }

            let vizoptions = {}
            vizoptions.engine = 'dot';
            vizoptions.fit = false;
            vizoptions.width = null;
            vizoptions.height = null;
            vizoptions.zoom = false;
            vizoptions.scale = 1;

            let styleDiv = {}

            return(
                <div>
                    <ViewDataSpec DataSpec={DataSpec} noLinks/>

                    {this.viewDataSpecSerialisation(DataSpec)}

                    <PD.Heading level="2">Index</PD.Heading>

                    {countClasses &&
                        <fieldset>
                            <legend>Classes</legend>
                            <PD.Table className="list">
                                <PD.Thead>
                                    <PD.Tr>
                                        <PD.Th>Name</PD.Th>
                                        <PD.Th>Description</PD.Th>
                                    </PD.Tr>
                                </PD.Thead>
                                <PD.Tbody>
                                    {
                                        DataSpec.Classes.map(
                                            (Class, pos) => 
                                            {                
                                                switch (Class.status){
                                                    case 'deprecated': return null;
                                                    default:

                                                        return (

                                                            <PD.Tr key={pos}>
                                                                <PD.Td>
                                                                    <PD.PdNavLink to={`#class_${Class._id}`} onClick={this.handleNavClick}>{Class.name}</PD.PdNavLink>
                                                                </PD.Td>
                                                                <PD.Td>
                                                                    {formatText(truncate(HtmlToText(Class.description), 400))}
                                                                </PD.Td>
                                                            </PD.Tr>
                                                        )
                                                }
                                            }
                                    
                                        )
                                    }
                                </PD.Tbody>
                            </PD.Table>
                        </fieldset>
                    }


                    {
                        countClasses &&
                            <div>
                                <PD.Heading level="2">Classes</PD.Heading>
                                {
                                    DataSpec.Classes.map(
                                        (Class, pos) =>    
                                        {
                                            
                                            switch (Class.status){
                                                case 'deprecated': return null;
                                                default:

                                                    const viz = this.dsd.vizClass({Class:Class}, {}, 'dot' );
                                                    return (
                                                        <fieldset id={`class_${Class._id}`}>
                                                            <ViewClass key={pos} Class={Class} noLinks/>

                                                            <fieldset>
                                                                <legend>visualisation</legend>
                                                                <div name="divVizClass" style={styleDiv} onClick={this.handleNavClick}>
                                                                    <Graphviz dot={viz.dot} options={vizoptions}/>
                                                                </div>
                                                            </fieldset>
                                                        </fieldset>
                                                    )
                                            }
                                        }
                                    )
                                }
                            </div>
                    }

                    <PD.Heading level="2">Renditions</PD.Heading>

                    {this.viewDataSpecRenditionJsonSchema(DataSpec)}

                </div>
            )
        }
    }


    viewDataSpecSerialisation(DataSpec){
        if (DataSpec){
            const viz = this.dsd.vizSerialisation({Serialisation:DataSpec.Serialisation}, {}, 'dot' );
            
            let vizoptions = {}
            vizoptions.engine = 'dot';
            vizoptions.fit = false;
            vizoptions.width = null;
            vizoptions.height = null;
            vizoptions.zoom = false;
            vizoptions.scale = 1;

            let styleDiv = {}


            return(
                <div>
                    <PD.Heading level="2">Serialisation</PD.Heading>

                    <fieldset>
                        <legend>visualisation</legend>
                        <div name="divVizDataSpec" style={styleDiv} onClick={this.handleNavClick}>
                            <Graphviz dot={viz.dot} options={vizoptions}/>
                        </div>
                    </fieldset>

                </div>
            )

        }
        return
    }


    viewDataSpecRenditionJsonSchema(DataSpec){
        if (DataSpec){
            let jsonSchema = JSON.stringify(this.dsd.makeDataSpecJsonSchema(DataSpec), null, "\t");

            return(
                <fieldset>
                    <legend>json schema</legend>
                    <pre>
                        {jsonSchema}   
                    </pre>
                </fieldset>
            )

        }

        
        
        

        return
    }


    render() {

        if (this.state.isLoaded){
            return(
                <div>
                    {this.state.Version &&
                        <>
                            <PD.Heading>{`Named Version: ${this.state.Version.name}`}</PD.Heading>
                            <PD.Button onClick={this.handleExport} name="btnExport" value="export" />
                           {this.viewVersion(this.state.Version)}
                        </>
                    }
                    {(() => {
                        switch (this.state.type) {
                            case "conceptmodel":
                                return (this.viewConceptModel(this.state.Document));
                            case "logicalmodel":
                                return (this.viewLogicalModel(this.state.Document));
                            case "datadictionary":
                                return (this.viewDataDictionary(this.state.Document));
                            case "dataspec":
                                return (this.viewDataSpec(this.state.Document));
                            case "process":
                                return (this.viewProcess(this.state.Document));
                        }
                    })()}


                </div>
            )
        }
        return null;

    };    
};
        
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <ViewVersion {...props} navigation={navigation} location={location} params={params} />;
  }
